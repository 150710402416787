.appointment-container {
  background-color: #f0f4f8;
  padding: 20px;
  text-align: center;
  font-family: "Montserrat";
}

.appointment-form {
  background-color: #ffffff;
  padding: 40px;
  margin: 20px auto;
  border-radius: 10px;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.appointment-form h3 {
  font-weight: bold;
  margin-bottom: 10px;
}

.appointment-form h1 {
  margin-bottom: 20px;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.form-row input,
.form-row select {
  flex: 1 1 calc(50% - 10px);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

.form-row input[type="date"],
.form-row input[type="time"] {
  flex: 1 1 calc(25% - 10px);
}

.appointment-form button {
  background-color: #0056b3;
  color: #ffffff;
  padding: 15px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  width: 100%;
  margin-top: 20px;
}

.appointment-form button:hover {
  background-color: #004494;
}

.appointment-form p {
  margin-top: 20px;
  font-size: 0.9rem;
  color: #333333;
}
