.error-page{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-family: "Montserrat";
}

.error-404{
  font-size: 300px;
  color: rgba(1, 82, 168, 1);
  height: 100%;
  justify-content: center;
  display: flex;
  font-weight: 800;
}

.error-not-found{
  font-weight: 700;
  font-size: 75px;
  display: flex;
  justify-content: center;
}

.error-desc{
  justify-content: space-evenly;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 2% 5%;
}
.go-to-homepage{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 127px;
  cursor: pointer;
  background-color: rgba(15, 227, 175, 1);
  height: 67px;
  margin-bottom:2%;
}

@media (max-width: 768px) {
  .error-404{
    font-size: 100px;
  }

  .error-not-found{
    font-size: 30px;
  }

  .error-desc{
    font-size: 12px;
  }
}