.image-icon {
  width: 100%;
 height:auto;
 max-width:100%;
  object-fit: cover;
}
.medical-professionals {
  flex: 1;
  position: relative;
  letter-spacing: 0.22em;
  line-height: 142%;
  text-transform: uppercase;
}
.sub-text {
  align-self: stretch;
  background-color: var(--color-aquamarine);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1px var(--padding-2xs);
}
.makes-your-health,
.our-team-of {
  align-self: stretch;
  position: relative;
}
.makes-your-health {
  font-size: var(--font-size-31xl);
  line-height: 114.5%;
}
.our-team-of {
  line-height: 142%;
  color: var(--color-gray-300);
}
.book-appointment {
  width: 710px;
  position: relative;
  line-height: 160%;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  flex-shrink: 0;
}
.button,
.title {
  align-self: stretch;
}
.button {
  border-radius: var(--br-5xs);
  background: linear-gradient(90deg, #0152a8, #0a67c8);
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-22xl);
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  color: var(--light-background-color);
}
.title {
  height: 243px;
  gap: var(--gap-base);
}
.banner-text,
.title,
.title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.title-wrapper {
  align-self: stretch;
  color: #143450;
  font-family: var(--font-source-sans-pro);
}
.banner-text {
  position: absolute;
  width: 47.22%;
  top: 36px;
  right: 47.84%;
  left: 4.94%;
  gap: var(--gap-base);
}
.banner {
  position:relative;
  width: 100%;
}
.know-more {
  position: relative;
  line-height: 160%;
  font-weight: 600;
}
.button1 {
  position: absolute;
  width: 11.6%;
  top: 2053px;
  right: 75.26%;
  left: 13.14%;
  border-radius: var(--br-5xs);
  background: linear-gradient(90deg, #0152a8, #0a67c8);
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs) var(--padding-22xl);
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  color: var(--light-background-color);
}
.departments {
  border: 0.5px solid #CECECE;
  letter-spacing: 0.42em;
  line-height: 142%;
  text-transform: uppercase;
  padding:1px 0px 1px 5px;
  width:fit-content;
}

.feel-like-home {
  width: 100%;
  position: relative;
  line-height: 130%;
  font-weight: 600;
  display: inline-block;
  flex-shrink: 0;
}
.title1 {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  font-size: var(--font-size-11xl);
}

.heading {
 display:flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 padding:80px 0px 40px 0px;
 row-gap:18px;
}
.sub-text2 {
  width: 146px;
  border: 0.5px solid var(--color-lightgray-100);
  box-sizing: border-box;
  flex-direction: row;
  align-items: flex-start;
  padding: var(--padding-10xs) var(--padding-3xl);
}
.we-provide-a {
  flex: 1;
  position: relative;
  line-height: 130%;
  font-weight: 600;
}
.heading1,
.title2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.title2 {
  align-self: stretch;
  flex-direction: row;
  font-size: var(--font-size-11xl);
}
.heading1 {
  position: absolute;
  width: 36.24%;
  top: 1661.1px;
  right: 50.92%;
  left: 12.85%;
  flex-direction: column;
  gap: var(--gap-smi);
  font-size: var(--font-size-sm);
}
.collaboratively-administrate-e1,
.dynamically-procrastinate-b2c {
  font-family: var(--font-source-sans-pro);
  color: var(--color-dimgray-100);
}
.dynamically-procrastinate-b2c {
  color: var(--color-darkslateblue-100);
}
.collaboratively-administrate-e {
  margin: 0;
}
.collaboratively-administrate-e-container {
  position: absolute;
  width: 36.42%;
  top: 1811px;
  left: 12.85%;
  line-height: 142%;
  display: inline-block;
  height: 155px;
  color: var(--color-dimgray-100);
}
.vector-icon {
  width: 19.8px;
  position: relative;
  height: 19.8px;
}
.years-of-excellence {
  position: relative;
  line-height: 142%;
}
.div,
.parent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.div {
  flex: 1;
  align-items: center;
  gap: var(--gap-4xs);
}
.parent {
  position: absolute;
  width: 28.51%;
  top: 1998px;
  right: 58.49%;
  left: 12.99%;
  align-items: flex-start;
  gap: 26px;
}
.icon,
.icon1 {
  position: absolute;
  top: 0;
  left: 308.2px;
  width: 286.7px;
  height: 514px;
  object-fit: cover;
}
.icon1 {
  left: 0;
}
.mark-broownn,
.multispecialist {
  position: relative;
  display: inline-block;
  flex-shrink: 0;
}
.mark-broownn {
  width: 179px;
  line-height: 130%;
  font-weight: 600;
  height: 44px;
}
.multispecialist {
  width: 154px;
  font-size: var(--navigation-size);
  line-height: 142%;
  color: #9a9a9a;
  height: 23px;
}
.name {
  position: absolute;
  top: 359px;
  left: 191.2px;
  background-color: var(--color-gray-300);
  width: 237.9px;
  height: 99px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-smi) var(--padding-4xl);
  box-sizing: border-box;
}
.img {
  align-self: stretch;
  position: relative;
  height: 514px;
}
.image {
  position: absolute;
  width: 41.31%;
  top: 1632px;
  right: 3.58%;
  left: 55.11%;
  height: 497px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-xl);
  color: var(--light-background-color);
}
.group-child,
.mask-group-icon {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  height: 457.7px;
}
.group-child {
  background-color: var(--color-darkslateblue-100);
}
.mask-group-icon {
  max-width: 100%;
  overflow: hidden;
}
.schedule-an-imperson {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 130%;
  display: inline-block;
  width: 687.7px;
  height: 193.8px;
}
.book-an-appointment,
.button2 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.book-an-appointment {
  flex: 1;
  position: relative;
  line-height: 160%;
  font-weight: 600;
  height: 13px;
  cursor: pointer;
}
.button2 {
  position: absolute;
  width: 57.15%;
  top: 194px;
  right: 24.09%;
  left: 18.76%;
  border-radius: var(--br-5xs);
  background-color: var(--light-background-color);
  height: 92px;
  flex-direction: row;
  padding: var(--padding-smi) var(--padding-15xl);
  box-sizing: border-box;
  font-size: var(--navigation-size);
  color: var(--color-gray-300);
}
.title3 {
  align-self: stretch;
  position: relative;
  height: 286px;
}
.heading2 {
  position: absolute;
  width: 47.79%;
  top: 79px;
  right: 29.17%;
  left: 23.04%;
  height: 301px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.rectangle-parent {
  align-self: stretch;
  position: relative;
  height: 350px;
}
.sub-text3 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.sub-text3 {
  width: 168px;
  border: 0.5px solid var(--color-lightgray-100);
  box-sizing: border-box;
  flex-direction: row;
  padding: var(--padding-10xs) var(--padding-6xs);
}
.feedbacks-about-our {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 130%;
  font-weight: 600;
  display: inline-block;
  width: 579.1px;
  height: 66.8px;
}
.title4 {
  align-self: stretch;
  position: relative;
  height: 66.8px;
  font-size: var(--font-size-11xl);
}
.text {
  position: absolute;
  width: 40.22%;
  top: 2804px;
  right: 32.56%;
  left: 27.23%;
  height: 152px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-smi);
  text-align: center;
  font-size: var(--font-size-sm);
}
.image-icon1 {
  width: 68px;
  position: relative;
  height: 68px;
  object-fit: cover;
}


.name1 {
  width: 100%;
  position: relative;
  height: 45.6px;
}
.text1 {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-lgi);
}
.icon2 {
  width: 100%;
  height: 34.1px;
}
.profile {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-166xl);
}
.line {
  width: 100%;
  position: relative;
  border-top: 1px solid var(--color-lightgray-200);
  box-sizing: border-box;
  height: 1px;
  margin-top: 2%;
}
.completely-synergize-resource1 {
  width: fit-content;
  display: inline-block;
  margin-top: 2%;
}
.star-child {
  width: 29.7px;
  position: relative;
  border-radius: var(--br-12xs);
  height: 29.7px;
}
.div2,
.star,
.text2 {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: var(--gap-9xs);
}
.div2,
.text2 {
  width: 100%;
  flex-direction: column;
  gap: var(--gap-4xl);
}
.div2 {
  width: 584px;
  background-color: var(--color-whitesmoke-200);
  height: 322px;
  padding: var(--padding-27xl);
  box-sizing: border-box;
  gap: var(--gap-xl);
}
.image-icon2 {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 68px;
  object-fit: cover;
}
.vanseena-adams {
  position: absolute;
  top: 0;
  left: 0;
  font-size: var(--font-size-xl);
  line-height: 114.5%;
  font-weight: 600;
  font-family: var(--font-montserrat);
  color: var(--color-gray-300);
}
.name2 {
  width: 178px;
  position: relative;
  height: 45.6px;
}
.line1,
.profile1 {
  align-self: stretch;
}
.profile1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-166xl);
}
.line1 {
  position: relative;
  border-top: 1px solid var(--color-lightgray-200);
  box-sizing: border-box;
  height: 1px;
}
.completely-synergize-resource2 {
  align-self: stretch;
  position: relative;
  line-height: 142%;
}
.div3,
.star1,
.text4 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.star1 {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-9xs);
}
.div3,
.text4 {
  flex-direction: column;
}
.text4 {
  align-self: stretch;
  height: 122px;
  gap: var(--gap-4xl);
}
.div3 {
  width: 611px;
  background-color: var(--color-whitesmoke-200);
  padding: var(--padding-27xl);
  box-sizing: border-box;
  gap: var(--gap-xl);
}
.mark-brown {
  position: absolute;
  top: 0;
  left: 0;
  font-size: var(--font-size-xl);
  line-height: 114.5%;
  font-weight: 600;
  color: var(--color-gray-300);
}
.name3 {
  width: 104px;
  position: relative;
  height: 45.6px;
}
.div4 {
  width: 584px;
  background-color: var(--color-whitesmoke-200);
  height: 311px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-27xl);
  box-sizing: border-box;
  gap: var(--gap-xl);
  font-family: var(--font-source-sans-pro);
}
.name4 {
  width: 145px;
  position: relative;
  height: 45.6px;
}
.div5,
.group {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.div5 {
  width: 611px;
  background-color: var(--color-whitesmoke-200);
  flex-direction: column;
  padding: var(--padding-27xl);
  box-sizing: border-box;
  gap: var(--gap-xl);
  font-family: var(--font-source-sans-pro);
}
.group {
  position: absolute;
  width: 87.8%;
  top: 2995px;
  right: 5.33%;
  left: 6.88%;
  height: 700px;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px 23px;
  color: var(--color-dimgray-100);
  font-family: var(--font-lato);
}
.medical-experts {
  border: 0.5px solid white;
  letter-spacing: 0.42em;
  line-height: 142%;
  text-transform: uppercase;
  padding:1px 0px 1px 5px;
  width:fit-content;
  color: white;

}
.sub-text4 {
  align-self: stretch;
  border: 0.5px solid var(--color-cornflowerblue-200);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-10xs) var(--padding-2xs);
}
.the-professional-doctors {
  position: relative;
  line-height: 130%;
  font-weight: 600;
  color:white;
}
.heading3 {
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-smi);
}
.heading3,
.text9,
.title5 {
  display: flex;
  justify-content: flex-start;
}
.text9 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
}
.title5 {
  width: 1213px;
  flex-direction: row;
  align-items: center;
  padding: 0 var(--padding-5xs) 0 0;
  box-sizing: border-box;
}
.image-icon5 {
  position: relative;
  object-fit: cover;
  width:300px;
  height:330px;
}
.image1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.dental-patient4 {
  position: relative;
  font-size: var(--navigation-size);
  line-height: 142%;
  font-family: var(--font-lato);
}
.text10 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-8xs);
  color:white;
}
.fb-icon,
.insta-icon,
.twiter-icon {
  width: 22.3px;
  position: relative;
  height: 21.4px;
}
.fb-icon,
.twiter-icon {
  width: 23px;
}
.twiter-icon {
  width: 21.5px;
  height: 17.2px;
}
.follow {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-8xl);
  justify-content: center;
}
.div6,
.follow,
.image2 {
  display: flex;
  justify-content: flex-start;
}
.div6 {
  flex: 1;
  background-color: var(--color-darkslateblue-200);
  flex-direction: column;
  align-items: center;
  padding: var(--padding-11xl);
  gap: var(--gap-4xl);
  cursor: pointer;
  font-family: var(--font-source-sans-pro);
}
.team-card {
  display:flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-darkslateblue-200);
  cursor: pointer;
  padding: var(--padding-11xl);
  row-gap:20px;
}
.image2 {
  flex-direction: row;
  align-items: flex-start;
}
.dr-helen-wilmore {
  position: relative;
  line-height: 114.5%;
  font-weight: 600;
}
.div7,
.follow1,
.text11 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-8xs);
}
.div7,
.follow1 {
  flex-direction: row;
  gap: var(--gap-8xl);
}
.div7 {
  flex: 1;
  background-color: var(--color-darkslateblue-200);
  flex-direction: column;
  justify-content: center;
  padding: var(--padding-11xl);
  gap: var(--gap-4xl);
}
.content1,
.div8,
.team1 {
  display: flex;
  justify-content: flex-start;
}
.div8 {
  flex: 1;
  background-color: var(--color-darkslateblue-200);
  flex-direction: column;
  align-items: center;
  padding: var(--padding-11xl);
  gap: var(--gap-4xl);
}
.content1,
.team1 {
  align-items: flex-start;
}
.content1 {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-58xl);
  text-align: center;
  font-size: var(--font-size-xl);
  font-family: var(--font-montserrat);
}
.team-content {
  display:grid;
  gap:40px;
  grid-template-columns: repeat(3, 1fr);

}
.team1 {
  width: 1213px;
  flex-direction: column;
  gap: var(--gap-5xl);
}
.line4 {
  position: relative;
  border-top: 1px solid var(--color-cornflowerblue-100);
  box-sizing: border-box;
  margin:20px 10px;
  height: 1px;
}
.content2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-81xl);
}
.content,
.counter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.counter {
  align-self: stretch;
  gap: var(--gap-19xl);
}
.content {
  width: 1239px;
  gap: var(--gap-21xl);
}
.sub-text-child,
.team {
  position: absolute;
  box-sizing: border-box;
}
.team-container {
  height:auto;
  display:flex;
  flex-direction: column;
  padding:40px 80px;
  row-gap:20px;
  background-color: var(--color-darkslateblue-100);
}
.team {
  width: 92.78%;
  top: 3770px;
  right: 3.54%;
  left: 3.67%;
  background-color: var(--color-darkslateblue-100);
  height: 944px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-45xl) var(--padding-348xl) var(--padding-24xl)
    var(--padding-72xl);
  font-size: var(--font-size-sm);
  color: var(--light-background-color);
}
.sub-text-child {
  top: 0;
  left: 0;
  border: 0.5px solid var(--color-lightgray-100);
  width: 183.5px;
  height: 26.9px;
}
.news-update {
  position: absolute;
  top: 3.4px;
  left: 13.2px;
  letter-spacing: 0.42em;
  line-height: 142%;
  text-transform: uppercase;
}
.sub-text5 {
  width: 183.5px;
  position: relative;
  height: 26.9px;
}
.latest-healthcare-articles {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 130%;
  font-weight: 600;
}
.title7 {
  width: 329px;
  position: relative;
  height: 39px;
  font-size: var(--font-size-11xl);
}
.heading4,
.text13 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.text13 {
  gap: var(--gap-smi);
}
.heading4 {
  position: absolute;
  width: 82.41%;
  top: 4805px;
  right: 13.7%;
  left: 3.89%;
  height: 541px;
  font-size: var(--font-size-sm);
}
.read-all-news {
  position: absolute;
  top: 4850.4px;
  left: 1248px;
  text-decoration: underline;
  line-height: 160%;
  color: var(--color-darkslateblue-100);
  text-align: center;
}
.image-icon8 {
  width: 220px;
  position: relative;
  height: 147.6px;
  object-fit: cover;
}
.sub-text6 {
  border: 0.5px solid var(--color-lightgray-100);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-10xs) var(--padding-2xs);
}
.what-is-a {
  width: 335.3px;
  position: relative;
  line-height: 130%;
  font-weight: 600;
  display: inline-block;
  flex-shrink: 0;
}
.title8 {
  flex-direction: row;
  align-items: flex-start;
  font-size: var(--font-size-xl);
}
.div9,
.sub-text7,
.text14,
.title8 {
  display: flex;
  justify-content: flex-start;
}
.text14 {
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-lg);
}
.div9,
.sub-text7 {
  flex-direction: row;
}
.div9 {
  position: absolute;
  top: 4948px;
  left: 731px;
  width: 585.3px;
  align-items: center;
  gap: var(--gap-11xl);
  font-size: var(--font-size-sm);
}
.sub-text7 {
  width: 149px;
  border: 0.5px solid var(--color-lightgray-100);
  box-sizing: border-box;
  align-items: flex-start;
  padding: var(--padding-10xs) var(--padding-6xs);
}
.join-us {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 130%;
  font-weight: 600;
  display: inline-block;
  width: 496.4px;
  height: 30px;
}
.title9 {
  align-self: stretch;
  position: relative;
  height: 30px;
  font-size: var(--font-size-11xl);
}
.main-content,
.row,
.text15 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.text15 {
  width: 496.4px;
  gap: var(--gap-smi);
}
.main-content,
.row {
  width: 691px;
  overflow: hidden;
}
.row {
  width: 692px;
}
.your-email {
  position: absolute;
  top: calc(50% - 14px);
  left: 20px;
  letter-spacing: 0.2px;
  line-height: 28px;
}
.form-control-input-style-1 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-8xs);
  background-color: #f9f9f9;
  border: 1px solid var(--color-gainsboro-100);
  box-sizing: border-box;
  overflow: hidden;
}
.input-group-text {
  position: absolute;
  top: calc(50% - 14px);
  left: calc(50% - 35px);
  letter-spacing: 0.2px;
  line-height: 28px;
  font-weight: 600;
}
.btn {
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  border-radius: 0 var(--br-8xs) var(--br-8xs) 0;
  background-color: var(--color-darkslateblue-100);
  border: 1px solid var(--color-gainsboro-100);
  box-sizing: border-box;
}
.btn,
.input-group,
.input-group-append {
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
}
.input-group-append {
  height: 100%;
  bottom: 0;
  width: 117px;
  text-align: center;
  font-size: var(--navigation-size);
  color: var(--light-background-color);
}
.input-group {
  width: 100%;
  left: 0;
  height: 58px;
}
.subscribe {
  width: 688px;
  position: relative;
  height: 58px;
  margin-top: 30px;
}
.col-md-8,
.row-sec- {
  width: 688px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.row-sec- {
  width: 1061px;
  height: 122px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  color: var(--second-text-color);
}
.container {
  position: absolute;
  top: 0;
  left: calc(50% - 525px);
  width: 1050px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-37xl) 0 var(--padding-45xl);
  box-sizing: border-box;
  gap: 80px;
}
.background-icon,
.layout-4 {

  width: 100%;
  
}
.layout-4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--light-background-color);
  height: 431px;
  text-align: center;
  font-size: var(--font-size-sm);
  row-gap:20px;
}
.background-icon {
  max-width: 100%;
  object-fit: cover;
    height: 350px;

}
.copyright-pharma {
  position: absolute;
  width: 19.42%;
  top: 462px;
  left: 40.84%;
  line-height: 200%;
  display: inline-block;
  height: 58px;
}
.icon-child,
.icon-item {
  position: absolute;
  top: 45.1px;
  left: 13.5px;
  border-radius: var(--br-10xl);
  background-color: var(--color-darkslateblue-100);
  width: 52.1px;
  height: 25.2px;
  transform: rotate(-60deg);
  transform-origin: 0 0;
}
.icon-item {
  top: 57.8px;
  left: 26.1px;
  background-color: var(--color-aquamarine);
  transform: rotate(-120deg);
}
.intersect-icon {
  position: absolute;
  top: 15.4px;
  left: 18.1px;
  width: 25.3px;
  height: 37.8px;
}
.icon6,
.pharma {
  position: relative;
}
.icon6 {
  width: 61.4px;
  height: 57.8px;
}
.pharma {
  line-height: 105%;
  font-weight: 600;
}
.logo {
  width: 175.4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-10xs);
}
.call-child {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: var(--color-aquamarine);
  width: 43.1px;
  height: 43.1px;
}
.vector-icon2 {
 
  height: 35.5%;
  width: 7.16%;

}
.call {
  width: 213.8px;
  position: relative;
  height: 43.1px;
}
.supportdoctoratecom {
  position: absolute;
  top: 3.9px;
  left: 51.8px;
  line-height: 200%;
}
.vector-icon3 {
  position: absolute;
  height: 31.09%;
  width: 7.94%;
  top: 34.57%;
  right: 86.16%;
  bottom: 34.34%;
  left: 5.91%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.mail {
  width: 221.8px;
  position: relative;
  height: 43.1px;
}
.fb-icon3,
.insta-icon1,
.twiter-icon3 {
  position: absolute;
  height: 100%;
  width: 19.1%;
  top: 0;
  right: 40.28%;
  bottom: 0;
  left: 40.61%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.insta-icon1,
.twiter-icon3 {
  width: 18.52%;
  right: 81.48%;
  left: 0;
}
.twiter-icon3 {
  height: 80.37%;
  width: 17.86%;
  top: 9.35%;
  right: 0;
  bottom: 10.28%;
  left: 82.14%;
}
.follow3 {
  width: 120.4px;
  position: relative;
  height: 21.4px;
}
.contact-details,
.div10 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.contact-details {
  width: 662px;
  gap: var(--gap-34xl);
  font-size: var(--navigation-size);
  color: var(--color-dimgray-100);
  font-family: var(--font-lato);
}
.div10 {
  width: 1244px;
  gap: var(--gap-343xl);
}
.a-simple-story-container {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 141%;
  font-weight: 600;
  display: inline-block;
  width: 369.6px;
  height: 158.3px;
}
.quotes1 {
  width: 369.6px;
  position: relative;
  height: 158.3px;
}
.quotes {
  width: 369.6px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-11xl);
}
.explore1 {
  position: relative;
  font-size: var(--font-size-xl);
  line-height: 114.5%;
  font-weight: 600;
  color: var(--color-gray-300);
}
.home {
  width: 69px;
}
.home,
.services,
.testimonals {
  position: relative;
  line-height: 200%;
  display: inline-block;
  height: 26px;
  flex-shrink: 0;
}
.services {
  width: 69px;
  cursor: pointer;
}
.testimonals {
  width: 91px;
}
.explore {
  width: 96px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-sm);
  color: var(--color-dimgray-100);
}
.style-guide-changelog-container {
  position: relative;
  font-size: var(--navigation-size);
  line-height: 200%;
  color: var(--color-dimgray-100);
}
.uitility {
  width: 136px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
  cursor: pointer;
  font-size: var(--font-size-xl);
}
.it-is-a {
  width: 324.1px;
  position: relative;
  font-size: var(--navigation-size);
  line-height: 142%;
  color: var(--color-dimgray-100);
  display: inline-block;
  height: 50.7px;
  flex-shrink: 0;
}
.text16 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  font-size: var(--font-size-xl);
}
.bg {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--light-background-color);
  width: 325.7px;
  height: 60px;
}
.your-email-address {
  position: absolute;
  top: 17px;
  left: 25.2px;
  line-height: 160%;
}
.button3 {
  width: 325.7px;
  position: relative;
  height: 60px;
  color: var(--color-silver-200);
}
.submit {
  position: relative;
  line-height: 160%;
  font-weight: 500;
}
.appoinment,
.button4,
.content4,
.div12 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.button4 {
  background-color: var(--color-darkslateblue-100);
  flex-direction: row;
  padding: var(--padding-mid) var(--padding-23xl);
  color: var(--light-background-color);
  font-family: var(--navigation);
}
.appoinment,
.content4,
.div12 {
  width: 325.7px;
  flex-direction: column;
  gap: var(--gap-base);
}
.content4,
.div12 {
  width: 1198.3px;
  flex-direction: row;
  gap: var(--gap-89xl);
  font-size: var(--navigation-size);
  font-family: var(--font-source-sans-pro);
}
.content4 {
  position: absolute;
  width: 85.97%;
  top: 48px;
  right: 5.67%;
  left: 8.36%;
  height: 346px;
  flex-direction: column;
  gap: var(--gap-44xl);
  font-size: var(--font-size-11xl);
  color: var(--color-gray-300);
  font-family: var(--navigation);
}
.content3 {
  position: absolute;
  width: 100.49%;
  top: 5849px;
  right: -0.28%;
  left: -0.21%;
  height: 556.8px;
  color: var(--color-dimgray-100);
}
.frame-child {
  position: absolute;
  top: 61px;
  left: 0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  background-color: var(--light-background-color);
  width: 100%;
  height: auto;
}
.welcome-to-pharma {
  flex: 1 1;
    position: relative;
    /* line-height: 160%; */
    display: inline-block;
    /* height: 24px; */
    padding: 0px 10px;
    text-decoration: none;
    color: white;
}
.icon7 {
  position: relative;
}
.abcgmailcom,
.div13 {
  position: relative;
  display: inline-block;
  flex-shrink: 0;
}

.top-bar {
  background-color: var(--color-darkslateblue-100);
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
  color: white;
  font-family: "Source Sans Pro";
}
.top-mobile-email-tag{
  display: flex;
  column-gap: 5px;
  align-items: center;
  text-decoration: none;
  color: white;
}
.main-mobile-email-tag{
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 12px;

}
@media (max-width: 851px) {
  .top-bar {
    flex-direction: column;
    align-items: flex-start; /* Wrap elements on smaller screens */
  }

  .top-nav-bar {
    flex-wrap: wrap; /* Wrap elements on smaller screens */
  }
 
}

/*.top-bar {
  position: absolute;
  background-color: var(--color-darkslateblue-100);
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs) var(--padding-65xl);
  box-sizing: border-box;
}*/
.about,
.home1 {
  letter-spacing: 0.01em;
  line-height: 100%;
  cursor: pointer;
  font-family: "Source Sans Pro";


}
.home1 {
  position: relative;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.about {
  cursor: pointer;
}
.about,
.icon9,
.pages {
  position: relative;
}
.pages {
  letter-spacing: 0.01em;
  line-height: 100%;
}
.icon9 {
  width: 18px;
  height: 18px;
  overflow: hidden;
  flex-shrink: 0;
  opacity: 0.5;
}
.button5,
.dropdown {
  border-radius: var(--br-5xs);
}
.button5 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--light-background-color);
  border: 1px solid var(--color-darkslateblue-100);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  /*padding: var(--padding-5xs) var(--padding-base);*/
}
.dropdown {
  width: 45px;
  position: relative;
  height: 24px;
}
.menu,
.menu-wrapper {
  position: absolute;
  width: 510px;
  height: 47px;
}
.menu {
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.menu-wrapper {
  top: 85px;
  left: 461px;
  color: var(--primary);
  font-family: var(--navigation);
}
.book-appointment1 {
  position: relative;
  line-height: 160%;
  cursor: pointer;
}
.book-appointment-wrapper,
.frame-item {
  position: absolute;
  background: linear-gradient(90deg, #0152a8, #0a67c8);
}
.book-appointment-wrapper {
  top: 79px;
  left: 1178px;
  border-radius: var(--br-4xs);
  width: 166px;
  height: 41px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  text-align: center;
}
.frame-item {
  top: 81px;
  left: 1116px;
  border-radius: 50%;
  width: 37px;
  height: 37px;
}
.search-icon {
  position: absolute;
  top: 88px;
  left: 1123px;
  width: 24px;
  height: 24px;
  overflow: hidden;
}
.frame-inner {
  width: 72.9px;
  position: relative;
  height: 50.3px;
}
.group-wrapper {
  position: absolute;
  width: 5.08%;
  top: 61px;
  right: 88.25%;
  left: 6.67%;
  height: 72px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.rectangle-group {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  height: 133px;
  color: var(--light-background-color);
  font-family: var(--font-calibri);
}
.angioplasty {
  width: 100%;
  top: 71.3px;
  left: 0;
  line-height: 114.5%;
  font-weight: 600;
}

.angioplasty-services {
  width: 100%;
  font-size: 20px;
  font-weight: 950;
}
.title-child {
  width: 54px;
  height: 54px;
  margin: 1%;
}
.our-team-of1,
.title10 {
  width: 100%;
  display: flex;
  height: 100%;
}
.our-team-of1 {
  width: 225.3px;
  font-size: var(--navigation-size);
  line-height: 142%;
  color: var(--color-dimgray-100);
  display: inline-block;
  height: 72.6px;
  flex-shrink: 0;
}
.image-services{
  height: 70%;
}
.our-team-of1-services,
.title10-services {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}
.sub-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.sub-content-services{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.icon10 {
  width: 16.8px;
  position: relative;
  height: 16.8px;
}
.button6,
.div14 {
  display: flex;
  justify-content: center;
  width: fit-content;
}


.button6 {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-5xs);
  font-size: var(--navigation-size);
  color: var(--color-darkslategray-100);
  font-family: var(--navigation);
}
.div14 {
  background-color: var(--color-whitesmoke-200);
  flex-direction: column;
  width: fit-content;
  justify-content: center;
  align-items: center;
  align-items: flex-start;
  padding: var(--padding-26xl) var(--padding-11xl);
  box-sizing: border-box;
}

.service-card{
  display: flex;
  width: 100%;
  height: 100%;
}
.div14-services {
  background-color: var(--color-whitesmoke-200);
  width: 50%;
  height: 70%;
  justify-content: center;
  align-items: center;
  padding: var(--padding-26xl) var(--padding-11xl);
  cursor: pointer;
  display: flex;
  flex-direction: row;
}


.div14-services:hover{
  background-color: gray;
}
.title11 {
  width: 94px;
  position: relative;
  height: 94.3px;
}

.title11-services {
  width: 100%;
  height: 94.3px;
}
.div15 {
  width: 299px;
  background-color: var(--color-whitesmoke-200);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-26xl) var(--padding-11xl);
  box-sizing: border-box;
}
.title12 {
  width: 57px;
  position: relative;
  height: 94.3px;
}
.div16 {
  width: 297px;
  background-color: var(--color-whitesmoke-200);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-26xl) var(--padding-11xl);
  box-sizing: border-box;
}
.image-icon9 {
  width: 298px;
  position: relative;
  height: 305.5px;
  object-fit: cover;
}
.eye-care {
  position: absolute;
  top: 71.5px;
  left: 0;
  line-height: 114.5%;
  font-weight: 600;
}
.title13 {
  width: 74px;
  position: relative;
  height: 94.5px;
}
.button9,
.div17 {
  display: flex;
  justify-content: flex-start;
}
.button9 {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-5xs);
  font-size: var(--navigation-size);
  color: var(--color-darkslategray-100);
}
.div17 {
  width: 295.8px;
  background-color: var(--color-whitesmoke-200);
  height: 305.1px;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--padding-26xl) var(--padding-11xl);
  box-sizing: border-box;
}
.icon14 {
  width: 315.2px;
  position: relative;
  height: 305.5px;
  object-fit: cover;
}
.title14 {
  width: 126px;
  position: relative;
  height: 94.3px;
}
.icon16 {
  position: absolute;
  height: 58.01%;
  width: 46.75%;
  top: 0;
  right: 53.25%;
  bottom: 41.99%;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.title15 {
  width: 117px;
  position: relative;
  height: 94.3px;
}
.frame-div {
  display:grid;
  width: 85%;
  margin: 0% 5% 0% 5%;
}

.frame-div-about-us {
  display:flex;
  justify-content: space-between;
  width: 95%;
  margin: 2% 2% 2% 2%;
}

.frame-div-2 {
  display:grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  margin-left: 5% 5%;
}
.landing {
  width: 100%;
  position: relative;
  background-color: var(--light-background-color);
  display:flex;
  flex-direction: column;
  text-align: left;
  font-size: var(--navigation-size);
  color: var(--color-gray-300);
  font-family: var(--font-source-sans-pro);
}
.second-container {
  display:flex;
  align-items: center;
  justify-content: space-between;
  padding:10px 40px;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
}
.second-menu-container {
  display:flex;
  align-items: center;
  column-gap: 40px;
  font-size: 18px;
  font-weight: 400;
}
.logo-style {
  width:200px;
  height:65px;
}
.search-container  {
  display:flex;
  align-items: center;
  column-gap: 20px;
}
.appointment-btn,.about-btn, .knowmore-btn {
  height:41px;
  font-size: 14px;
  border-radius: 8px;
  padding:0 20px;
  text-align: center;
  cursor: pointer;
  border:none;
  background-color: #0152A8;
  color: white;
  font-weight: 400;
}
.search {
  display:flex;
  align-items: center;
  justify-content: center;
  width:40px;
  height:40px;
  border-radius: 50%;
  background-color: #0152A8;
}
.ham-dropdown {
  display:none;
  width:25px;
}
@media (max-width: 1024px) {
  .image-text {
    row-gap: 6px !important;
    top:50px !important;
    left:30px !important;
  }
  .frame-div {
    grid-template-columns: repeat(3, 1fr);
  }
  .about-us-container {
    display:flex;
    align-items: flex-start;
    column-gap: 10px;
    margin-top: 15%;
  
  }
  .about-us-content-second{
    display:flex;
    flex-direction: column;
    row-gap:20px;
  }
  .about-us-content {
    width:50% !important;
    display:flex;
    flex-direction: column;
    row-gap:20px;
  }
  .img-width {
    width:100%;
    display:flex;
    align-items: end;
    justify-content: end;
  }
  .schedule-img {
    width:100%;
    object-fit: cover;
  }

  .counter-content {
    
    grid-template-columns: repeat(4, 1fr) !important;
    
    
  }
  .team-content {
    gap:20px;
    grid-template-columns: repeat(2, 1fr);
  
  }
  
  
}

@media (max-width: 768px) {
  .top-bar{
    justify-content: space-between;
    flex-direction: column;
    column-gap: 0px;
  }
  .top-mobile-email-tag{
    column-gap: 0px;
  }
  .bottom-subcontent-icon {
    display:flex;
    flex-direction: row;
    align-items: center;
    column-gap: 1%;
    width: 100%;
  }
  .ham-dropdown {
    display:flex;
  }
  .second-menu-container {
    display: none;
  }
  .second-container-logo {
    display: none;
  }
  .search {
    display:none;
  }
  .image-text {
    row-gap: 6px !important;
    top:40px !important;
    left:20px !important;
  }
  .img-subtext-one {
    font-size:13px !important;
    padding:1px 4px !important;
  }
  .img-subtext-two {
    font-weight: 600 !important;
    font-size: 30px !important;
  }
  .img-subtext-three {
    font-size: 12px !important;
  }
  .frame-div {
    grid-template-columns: repeat(2, 1fr);
  }
  .about-us-container {
    flex-direction: column;
    row-gap: 10px;
    padding:20px 30px 20px 30px;
  
  }
  .about-image {
    display: flex;
    align-items: start !important;
    justify-content: start !important;
    width:100% !important;
  }
  .about-us-content {
    width:100% !important;
    row-gap:10px !important;
  }
  .about-us-content-second{
    width:100% !important;
    row-gap:10px !important;
  }
  .img-width {
    width:250px;
    display: flex !important;;
    align-items: center !important;
    justify-content: center !important;
  }
  .schedule-img {
    width:100%;
    object-fit: cover;
  }
  .schedule-content {
    
    font-size: 20px !important;
    line-height: 20px !important;
   
    left:30% !important;
    top:20% !important;
    
    width:300px !important;
  
  }
  
  .image-icon5 {
   
    width: 250px !important;;
   
  }
  .team-container {
    padding:10px 30px !important;
  }
  
  .counter-content {
    grid-template-columns: repeat(3, 1fr) !important;
   
    
  }
  .bottom-subcontent-2 {
    column-gap: 50px !important;
  
  }
  .bottom-title{
    display:none;
  }
  .bottom-content {
    row-gap: 10px !important;
  }
  .bottom-explore {

    row-gap: 10px !important;
  }
  
}
@media (max-width: 450px) {
  body{
    width: 100%;
    height: 100vh;
  }
  .knowmore-btn{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title1{
    width: 300px;
  }
  .about-us-container{
    padding: 0 0 0 0;
  }
  .subscribe-joinus{
    width: 5%;
  }
  .frame-div-about-us{
    flex-wrap: wrap;
  }
  .bottom-content-2 {
    column-gap: 10px !important;
    font-size: 11px !important;
   
  
  }
  .bottom-explore {

    row-gap: 2px !important;
  }
  .bottom-appointment-text {
    font-size: 10px!important;
  }
  .image-text {
    row-gap: 4px !important;
    top:12px !important;
    left:20px !important;
  }
  .img-subtext-one {
    font-size:11px !important;
  }
  .img-subtext-two {
    font-weight: 500 !important;
    font-size: 20px !important;
  }
  .img-subtext-three {
    font-size: 8px !important;
  }
  .appointment-btn {
    height:25px !important;
    font-size: 10px !important;
    border-radius: 6px !important;
    padding:0 10px !important;
  }
  .heading {
    padding:40px 0px ;
   }
   .frame-div {
    grid-template-columns: repeat(1, 1fr);
  }
  .schedule-content {
    
    font-size: 16px !important;
    line-height: 16px !important;
   
    left:27% !important;
    top:18% !important;
    
    width:200px !important;
  
  }
  .schedule-btn {
    border-radius: 6px !important;
    
    height: 30px !important;
   
    font-size: 12px !important;
   
   width:100% !important;
   margin: 2% 0px !important;
    
  }
  .frame-div-2 {
    grid-template-columns: repeat(1, 1fr);
  }
  .team-content {
    gap:20px;
    grid-template-columns: repeat(1, 1fr);
  
  }
  .counter-content {
    grid-template-columns: repeat(2, 1fr) !important;
    gap:10px;
    
  }
  .subscribe {
    width: 400px;
    position: relative;
    height: 58px;
    margin-top: 30px;
  }
  /* .bottom-subcontent-2 {
    display:none !important;
  } */
  .bottom-content-1 {
    display:flex;
    flex-direction: column;
  }

  .bottom-subcontent-info{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .bottom-subcontent-2 {
    column-gap: 5px !important;
    justify-content: space-between;
  }
  .bottom-content {
    left:10px !important;
    right:10px;
  }
  .bottom-appointment-content{

    column-gap: 20px !important;
  }
  .sb-btn {
    height: 25px !important;
   
    width:60px;
    font-size: 11px;
  }
  .appointment-input {
   
    height: 25px !important;
   
  }
  .pharma {
    width:40px !important;
  }
  .call-icon {
    width:15px !important;
  }
  .call-number {
    font-size: 11px !important;
  }
  .bottom-vector-icon{
    width: 23px !important;
    height: 23px !important;
  }
  .background-icon {
    max-width: 100% !important;
    height: 210px !important;
    object-fit: cover !important;
  }

  

  
}
.whitespace {
  white-space: nowrap;
}
.bottom-content-2 {
  display:flex;
  flex-direction: row;
  column-gap: 30%;
  align-items: start;

}
.bottom-title {
  font-size: 28px;
  font-weight: 700;
  width:300px;
}
.bottom-explore {
  display:flex;
  flex-direction: column;
  row-gap: 10px;
  width: 100%;
}
.bottom-appointment-text {
  font-size: 14px;
}
.appointment-input {
  border: none;
  height: 40px;
  background-color: white;
  width:100%;
}
.sb-btn {
  height: 38px;
  background-color: #0152A8;
  text-align: center;
  width:80px;
  color: white;
  border:none
}
.bottom-appointment-content{
  display:flex;
  flex-direction: row;
  column-gap: 5%;
}

.image-text {
  position:absolute;
  display:flex;
  flex-direction: column;
  row-gap: 15px;
  top:100px;
  left:200px;
}
.img-subtext-one {
  font-size:15px;
  font-weight: 400;
  background-color: #0FE3AF;
  width:fit-content;
  padding:2px 6px;
}
.img-subtext-two {
  font-weight: 700;
  font-size: 50px;
  color:#143450;
}
.img-subtext-three {
  color:#1C1C1C;
  font-size: 15px;
  font-weight: 500;
  width: 35%;
}
.about-us-container {
  display:flex;
  align-items: flex-start;
  column-gap: 30px;
  padding:30px 80px 40px 80px;

}
.about-us-content {
  width:50%;
  display:flex;
  flex-direction: column;
  row-gap:20px;
}
.about-us-content-second {
  display:flex;
  flex-direction: column;
  row-gap:20px;
}
.about-us-status {
  display:flex;
  align-items: center;
  column-gap: 20px;
}
.about-status {
  display:flex;
  align-items: center;
  column-gap: 5px;
}
.about-image {
  width: 50%;
  display: flex;
  align-items: end;
  justify-content: end;
}
.schedule-img-container {
  display: flex;
  height: 300px;
  background-image: url("../../../../public/Group\ 2.svg");
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  color: white;
}
.schedule-img {
  width:100%;
}
.schedule-content {
  position: relative;
  top: -180px;
  font-weight: 700;
  font-size: 30px;
  line-height: 30px;
  color:white;
  text-align: center;

}
.schedule-btn-app {
  border: none;
  height: 56px;
  width: 180px;
  text-align: center;
  color: #1C1C1C;
  background-color: #0FE3AF;
  font-size: 14px;
  cursor: pointer;
}

.schedule-btn {
  border: none;
  height: 56px;
  text-align: center;
  color: #1C1C1C;
  background-color: #0FE3AF;
  font-size: 14px;
  cursor: pointer;
}

.schedule-btn-call {
  border: none;
  height: 56px;
  width: 180px;
  text-align: center;
  color: #1C1C1C;
  font-size: 14px;
  background-color: white;
  margin: 2% 5%;
  cursor: pointer;
}
.counter-content {
  display:grid;
  grid-template-columns: repeat(5, 1fr);
  gap:20px;
  
}
.main-count {
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color:white;
  row-gap: 15px;;
}
.count {
  font-size: 40px;
  font-weight: 700;
}

.year-count {
  border: 0.5px solid #CECECE;
  letter-spacing: 0.42em;
  line-height: 142%;
  text-transform: uppercase;
  padding:1px 0px 1px 5px;
  width:fit-content;
  font-size: 14px;
}
.count-alignment {
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.image-relative {
  position: relative;
}
.bottom-content {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  position: absolute;
  top:20px;
  left:50px;
  bottom:20px;
  right:50px;
}
.bottom-content-1 {
  display:flex;
  align-items: center;
  justify-content: space-between;
}
.bottom-subcontent-2 {
  width: 100%;
  display:flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10%;
  margin: 20px;
}
.bottom-subcontent-1 {
  display:flex;
  column-gap: 10px;
  align-items: center;
  white-space: nowrap;
}
.bottom-pharma {
  font-size: 28px;
  font-weight: 600;
}
.bottom-vector-icon {
  display:flex;
  align-items: center;
  width:30px;
  height:30px;
  justify-content: center;
  background-color: #0FE3AF;
  border:none;
  border-radius: 50%;
}
.bottom-subcontent-icon {
  display:flex;
  align-items: center;
  column-gap: 20%;
  width: 100%;
}

.testimonial-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  padding: 16px;
}

.testimonial-grid > div {
  flex: 1 1 calc(50% - 32px); /* 50% width minus gap */
  box-sizing: border-box;
  max-width: 500px;
}

.testimoni-section{
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: "Source Sans Pro";
}
.title-testimoni{
  justify-self: center;
  align-self: center;
  margin-left: 10%;
  margin-right: 10%;
  font-weight: 600;
  font-size: 30px;
}

.joinus-section{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 400px;
  font-family: "Source Sans Pro";
}

.subscribe-joinus{
  display: flex;
  width: 100%;
  justify-content: center;
}

.joinus-input{
  width: 688px;
height: 52px;
border-radius: 5px 0 0 5px;

}

.joinus-title{
  font-size: 30px;
  font-weight: 600;
}

.subscribe-btn{
height: 58px;  
width: 117px;
border-radius: 0 5px 5px 0;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
background-color: #0152a8;
color: white;
font-weight: 600;
}

