.about-us16,
.home-about8 {
  position: absolute;
  top: 163px;
  left: calc(50% - 102px);
  line-height: 114.5%;
  display: inline-block;
  width: 198px;
  height: 47px;
}
.home-about8 {
  top: 126px;
  left: calc(50% - 92px);
  font-size: var(--font-size-5xl);
  font-weight: 600;
  width: 177px;
  height: 24px;
}
.photo11 {
  top: -49px;
  left: -11.5px;
  background-color: var(--color-gray-400);
  width: 1461.9px;
  height: 643.4px;
}

.div-transparent{
      /* background: white; */
      background-color: white !important;
      background-color: var(--color-whitesmoke-200);
      flex-direction: column;
      align-items: flex-start;
      padding: var(--padding-26xl) var(--padding-11xl);
      box-sizing: border-box;
      border-radius: 1px black;
      border-style: ridge;
      /* border-color: grey; */
      /* border-radius: 1px; */
      /* border: 1px grey;*/
}
.contact-tag-us{
  position: absolute;
  top: 71.3px;
  left: 0;
  line-height: 114.5%;
  font-weight: 600;
  color:black;
}
.bg19,
.image13,
.photo11 {
  position: absolute;
}
.image13 {
  top: 133px;
  left: calc(50% - 717px);
  width: 1440px;
  height: 352px;
  display: none;
  font-size: var(--font-size-31xl);
}
.bg19 {
  top: 0;
  left: 0;
  background-color: var(--light-background-color);
  border: 1px solid var(--color-gainsboro-400);
  box-sizing: border-box;
  width: 490px;
  height: 64px;
}
.john-david {
  position: absolute;
  top: 17.5px;
  left: 31px;
  line-height: 160%;
}
.btn12,
.full-name {
  position: absolute;
  left: 0;
}
.btn12 {
  top: 32px;
  width: 490px;
  height: 64px;
}
.full-name {
  top: 0;
  line-height: 160%;
  text-transform: capitalize;
  font-weight: 600;
  font-family: var(--font-montserrat);
  color: var(--color-gray-100);
}
.name8 {
  width: 490px;
  position: relative;
  height: 96px;
}
.bg20 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--light-background-color);
  border: 1px solid var(--color-gainsboro-400);
  box-sizing: border-box;
  width: 488px;
  height: 64px;
}
.exampleyourmailcom {
  position: absolute;
  top: 17.5px;
  left: 31px;
  line-height: 160%;
  color: inherit;
  display: inline-block;
  width: 199px;
  text-decoration: none;
}
.btn13 {
  position: absolute;
  top: 32px;
  left: 0;
  width: 488px;
  height: 64px;
}
.mail9 {
  width: 488px;
  position: relative;
  height: 96px;
}
.text72 {
  width: 998px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.btn14 {
  position: absolute;
  top: 34px;
  left: 0;
  width: 490px;
  height: 64px;
}
.company1 {
  width: 490px;
  position: relative;
  height: 98px;
}
.btn15 {
  position: absolute;
  top: 34px;
  left: 0;
  width: 488px;
  height: 64px;
}
.subject1 {
  width: 488px;
  position: relative;
  height: 98px;
}
.we-can-help {
  position: relative;
  line-height: 160%;
  text-transform: capitalize;
  font-weight: 600;
}
.bg23 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--light-background-color);
  border: 1px solid var(--color-gainsboro-400);
  box-sizing: border-box;
  width: 1000px;
  height: 300px;
}
.type-your-message {
  position: absolute;
  top: 31px;
  left: 31px;
  line-height: 160%;
}
.btn16 {
  width: 1000px;
  position: relative;
  height: 300px;
  color: var(--color-silver-200);
  font-family: var(--navigation);
}
.message2,
.sub-content12 {
  width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.message2 {
  gap: var(--gap-5xs);
  color: var(--color-gray-100);
  font-family: var(--font-montserrat);
}
.sub-content12 {
  gap: var(--gap-xl);
}
.send-message {
  position: relative;
  line-height: 160%;
  font-weight: 500;
}
.button53,
.content58,
.form4 {
  display: flex;
  justify-content: flex-start;
}
.button53 {
  background-color: var(--color-darkslateblue-100);
  flex-direction: row;
  align-items: flex-start;
  padding: var(--padding-mid) 44px;
  text-align: center;
  color: var(--light-background-color);
}
.content58,
.form4 {
  flex-direction: column;
  align-items: center;
}
.content58 {
  width: 1000px;
  gap: var(--gap-2xl);
}
.form4 {
  position: absolute;
  top: 976px;
  left: calc(50% - 624px);
  background: linear-gradient(151.16deg, #dcedfe, #f5f7ff);
  width: 1200px;
  padding: var(--padding-81xl);
  box-sizing: border-box;
  text-align: left;
  color: var(--color-silver-200);
  font-family: var(--navigation);
}
.contact-us10 {
  position: relative;
  letter-spacing: 0.42em;
  line-height: 142%;
  text-transform: uppercase;
}
.sub-text22 {
  width: 183px;
  border: 0.5px solid var(--color-lightgray-100);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-10xs) var(--padding-3xl);
}
.get-in-touch {
  position: relative;
  line-height: 130%;
  font-weight: 600;
}
.content59,
.text73,
.title43 {
  width: 336px;
  display: flex;
  justify-content: flex-start;
}
.title43 {
  flex-direction: row;
  align-items: flex-start;
  font-size: var(--font-size-11xl);
  font-family: var(--font-montserrat);
}
.content59,
.text73 {
  flex-direction: column;
}
.content59 {
  align-items: center;
  gap: var(--gap-smi);
}
.text73 {
  align-items: flex-start;
}
.icon60 {
  width: 85px;
  position: relative;
  height: 85px;
}
.north-dunbar-st {
  position: absolute;
  top: 36.5px;
  left: 0;
  line-height: 142%;
  display: inline-block;
  width: 204.7px;
}
.address1 {
  position: absolute;
  top: 0;
  left: 60.3px;
  font-size: var(--font-size-xl);
  line-height: 114.5%;
  font-weight: 600;
  font-family: var(--font-montserrat);
  color: var(--color-gray-300);
}
.cntnt {
  width: 204.7px;
  position: relative;
  height: 82.5px;
}
.content61,
.location,
.text74 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.content61 {
  width: 204.7px;
  align-items: center;
  gap: var(--gap-11xl);
}
.location,
.text74 {
  align-items: flex-start;
}
.location {
  width: 386.7px;
  border: 1px solid var(--color-gainsboro-200);
  box-sizing: border-box;
  padding: var(--padding-42xl) var(--padding-72xl);
}
.p {
  margin: 0;
}
.call-us {
  position: absolute;
  top: 0;
  left: 67.3px;
  font-size: var(--font-size-xl);
  line-height: 114.5%;
  font-weight: 600;
  font-family: var(--font-montserrat);
  color: var(--color-gray-300);
}
.text75 {
  width: 204.7px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.supportdoctoratecom-yourmail {
  position: absolute;
  top: 36.5px;
  left: 0;
  line-height: 142%;
  color: inherit;
  display: inline-block;
  width: 204.7px;
  text-decoration: none;
}
.e-mail-us {
  position: absolute;
  top: 0;
  left: 53.3px;
  font-size: var(--font-size-xl);
  line-height: 114.5%;
  font-weight: 600;
  font-family: var(--font-montserrat);
  color: var(--color-gray-300);
}
.content60 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.address,
.contact-us9 {
  width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: var(--navigation-size);
  color: var(--color-dimgray-100);
}
.contact-us9 {
  position: absolute;
  top: 499px;
  left: calc(50% - 624px);
  justify-content: flex-start;
  gap: var(--gap-31xl);
  font-size: var(--font-size-sm);
  color: var(--color-gray-300);
  font-family: var(--font-lato);
}
.photo-icon1 {
  position: absolute;
  top: 133px;
  left: calc(50% - 720px);
  width: 1440px;
  height: 352px;
  object-fit: cover;
}
.image-1009 {
  position: absolute;
  top: -887px;
  left: -149.9px;
  width: 2044.1px;
  height: 1362.9px;
}
.we-are-ready-container {
  width: 502px;
  position: relative;
  line-height: 114.5%;
  display: inline-block;
  flex-shrink: 0;
}
.banner-text2 {
  position: absolute;
  top: 243px;
  left: 464px;
  width: 502px;
  height: 92px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-21xl);
  font-family: var(--font-montserrat);
}
.background-icon9 {
  position: absolute;
  top: 0;
  left: 0;
  width: 1440px;
  height: 556.8px;
}
.copyright-pharma8 {
  position: absolute;
  top: 462px;
  left: calc(50% - 81.9px);
  line-height: 200%;
  display: inline-block;
  width: 279.6px;
  height: 58px;
}
.icon-child13,
.icon-child14 {
  position: absolute;
  top: 45.1px;
  left: 13.5px;
  border-radius: var(--br-10xl);
  background-color: var(--color-darkslateblue-100);
  width: 52.1px;
  height: 25.2px;
  transform: rotate(-60deg);
  transform-origin: 0 0;
}
.icon-child14 {
  top: 57.8px;
  left: 26.1px;
  background-color: var(--color-aquamarine);
  transform: rotate(-120deg);
}
.intersect-icon8 {
  position: absolute;
  top: 15.4px;
  left: 18.1px;
  width: 25.3px;
  height: 37.8px;
}
.icon63 {
  width: 61.4px;
  position: relative;
  height: 57.8px;
}
.pharma8 {
  position: relative;
  line-height: 105%;
  font-weight: 600;
}
.logo8 {
  width: 175.4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-10xs);
}
.div90 {
  position: absolute;
  top: 3.9px;
  left: 51.8px;
  line-height: 200%;
}
.call-child6 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: var(--color-aquamarine);
  width: 43.1px;
  height: 43.1px;
}
.vector-icon22 {
  position: absolute;
  height: 35.5%;
  width: 7.16%;
  top: 31.79%;
  right: 86.48%;
  bottom: 32.71%;
  left: 6.36%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.call9 {
  width: 213.8px;
  position: relative;
  height: 43.1px;
}
.vector-icon23 {
  position: absolute;
  height: 31.09%;
  width: 7.94%;
  top: 34.57%;
  right: 86.16%;
  bottom: 34.34%;
  left: 5.91%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.mail11 {
  width: 221.8px;
  position: relative;
  height: 43.1px;
}
.fb-icon14,
.insta-icon10,
.twiter-icon14 {
  position: absolute;
  height: 100%;
  width: 19.1%;
  top: 0;
  right: 40.28%;
  bottom: 0;
  left: 40.61%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.insta-icon10,
.twiter-icon14 {
  width: 18.52%;
  right: 81.48%;
  left: 0;
}
.twiter-icon14 {
  height: 80.37%;
  width: 17.86%;
  top: 9.35%;
  right: 0;
  bottom: 10.28%;
  left: 82.14%;
}
.follow14 {
  width: 120.4px;
  position: relative;
  height: 21.4px;
}
.contact-details8,
.div89 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.contact-details8 {
  width: 662px;
  gap: var(--gap-34xl);
  font-size: var(--navigation-size);
  color: var(--color-dimgray-100);
  font-family: var(--font-lato);
}
.div89 {
  width: 1244px;
  gap: var(--gap-343xl);
}
.a-simple-story-container8 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 141%;
  font-weight: 600;
  display: inline-block;
  width: 369.6px;
  height: 158.3px;
}
.quotes21 {
  width: 369.6px;
  position: relative;
  height: 158.3px;
}
.quotes20 {
  width: 369.6px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-11xl);
}
.explore17 {
  position: relative;
  font-size: var(--font-size-xl);
  line-height: 114.5%;
  font-weight: 600;
  color: var(--color-gray-300);
}
.home16,
.news8,
.testimonals8 {
  position: relative;
  line-height: 200%;
  display: inline-block;
  height: 26px;
  flex-shrink: 0;
}
.home16 {
  width: 69px;
  cursor: pointer;
}
.news8,
.testimonals8 {
  width: 91px;
}
.news8 {
  width: 69px;
}
.explore16 {
  width: 96px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-sm);
  color: var(--color-dimgray-100);
}
.uitility-pages8 {
  position: relative;
  line-height: 114.5%;
  font-weight: 600;
}
.style-guide-changelog-container8 {
  position: relative;
  font-size: var(--navigation-size);
  line-height: 200%;
  color: var(--color-dimgray-100);
}
.uitility8 {
  width: 136px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
  cursor: pointer;
  font-size: var(--font-size-xl);
}
.it-is-a8 {
  width: 324.1px;
  position: relative;
  font-size: var(--navigation-size);
  line-height: 142%;
  color: var(--color-dimgray-100);
  display: inline-block;
  height: 50.7px;
  flex-shrink: 0;
}
.text77 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  font-size: var(--font-size-xl);
}
.bg24 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--light-background-color);
  width: 325.7px;
  height: 60px;
}
.your-email-address8 {
  position: absolute;
  top: 17px;
  left: 25.2px;
  line-height: 160%;
}
.button54 {
  width: 325.7px;
  position: relative;
  height: 60px;
  color: var(--color-silver-200);
}
.appoinment8,
.button55,
.content67,
.div91 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.button55 {
  background-color: var(--color-darkslateblue-100);
  flex-direction: row;
  padding: var(--padding-mid) var(--padding-23xl);
  color: var(--light-background-color);
  font-family: var(--navigation);
}
.appoinment8,
.content67,
.div91 {
  width: 325.7px;
  flex-direction: column;
  gap: var(--gap-base);
}
.content67,
.div91 {
  width: 1198.3px;
  flex-direction: row;
  gap: var(--gap-89xl);
  font-size: var(--navigation-size);
  font-family: var(--font-source-sans-pro);
}
.content67 {
  position: absolute;
  top: 48px;
  left: 120.4px;
  width: 1264.9px;
  height: 397px;
  flex-direction: column;
  gap: var(--gap-44xl);
  font-size: var(--font-size-11xl);
  color: var(--color-gray-300);
  font-family: var(--navigation);
}
.content66,
.frame-child20 {
  position: absolute;
  width: 1440px;
}
.content66 {
  top: 1901px;
  left: -6px;
  height: 556.8px;
  text-align: left;
  color: var(--color-dimgray-100);
}
.frame-child20 {
  top: 61px;
  left: 0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  background-color: var(--light-background-color);
  height: 72px;
}
.home17 {
  position: relative;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.about9,
.home17 {
  letter-spacing: 0.01em;
  line-height: 100%;
  cursor: pointer;
}
.about9,
.icon64,
.pages8 {
  position: relative;
}
.pages8 {
  letter-spacing: 0.01em;
  line-height: 100%;
}
.icon64 {
  width: 18px;
  height: 18px;
  overflow: hidden;
  flex-shrink: 0;
  opacity: 0.5;
}
.button56,
.dropdown8 {
  border-radius: var(--br-5xs);
}
.button56 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--light-background-color);
  border: 1px solid var(--black);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-base);
}
.dropdown8 {
  width: 45px;
  position: relative;
  height: 24px;
}
.menu8 {
  position: absolute;
  top: 86px;
  left: 460px;
  width: 510px;
  height: 47px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  cursor: pointer;
  color: var(--primary);
  font-family: var(--navigation);
}
.background11 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-darkslateblue-100);
  width: 1440px;
  height: 61px;
}
.welcome-to-pharma8 {
  position: absolute;
  top: 17.3px;
  left: calc(50% - 622.9px);
  line-height: 160%;
  display: inline-block;
  width: 353.5px;
}
.div92,
.icon65 {
  position: absolute;
}
.div92 {
  top: 18px;
  left: 1001.4px;
  line-height: 160%;
  display: inline-block;
  width: 132.2px;
  height: 18px;
}
.icon65 {
  top: 13px;
  left: 958.3px;
  width: 34.7px;
  height: 34.6px;
}
.abcgmailcom8 {
  position: absolute;
  top: 18px;
  left: 1222.7px;
  line-height: 160%;
  display: inline-block;
  width: 105.1px;
}
.icon66,
.top-bar8 {
  position: absolute;
  top: 13px;
  left: 1179.6px;
  width: 34.7px;
  height: 34.6px;
}
.top-bar8 {
  top: 0;
  left: 0;
  width: 1440px;
  height: 61px;
}
.book-appointment8 {
  position: relative;
  line-height: 160%;
}
.book-appointment-wrapper5 {
  position: absolute;
  top: 79px;
  left: 1178px;
  border-radius: var(--br-4xs);
  background: linear-gradient(90deg, #0152a8, #0e6acc);
  width: 166px;
  height: 41px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
}
.rectangle-parent7 {
  position: absolute;
  top: 0;
  left: 0;
  width: 1440px;
  height: 133px;
  text-align: left;
  font-family: var(--font-calibri);
}
.frame-child21 {
  width: 73px;
  position: relative;
  height: 50.3px;
}
.contact-us-inner {
  position: absolute;
  top: 61px;
  left: 96px;
  height: 72px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.contact-us-child {
  position: absolute;
  top: 81px;
  left: 1112px;
  border-radius: 50%;
  background: linear-gradient(90deg, #0152a8, #0a67c8);
  width: 37px;
  height: 37px;
}
.search-icon10 {
  position: absolute;
  top: 88px;
  left: 1119px;
  width: 24px;
  height: 24px;
  overflow: hidden;
}
.contact-third-row{
  width: 100%;
}
.consult-contact-main{
  position: absolute;
  top: 105%;
  left: 22%;
  right: 10%;
  width: 50%;
  background-color: #DCDCDC;
  padding: 80px 60px 20px 60px;
}

.contact-us8 {
  width: 100%;
  position: relative;
  background-color: var(--light-background-color);
  display:flex;
  flex-direction: column;
  height: auto;
  text-align: left;
  font-size: var(--navigation-size);
  color: var(--color-gray-300);
  font-family: var(--font-source-sans-pro);
}
