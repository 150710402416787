.footer-section{
    width: 95%;
    height: 100%;
    font-family: 'Source Sans Pro';
    background-image: url("../../../../public/background.svg");
    padding: 5%;

}

.footer-content-data{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.footer-data{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.footer-logo-image {
    width: 100%;
    height: 100%;
}

.footer-info-section{
    display: flex;
    justify-content: space-between;
    width: 50%;

}
.footer-info{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.footer-number, .footer-email{
    display: flex;
    font-family: 'Lato', sans-serif;


}

.call-num{
    align-self: center;
    justify-content: center;
}

.footer-share-links{
    display: flex;
    column-gap: 8%;
    width: 50%;
    align-items: center;
    justify-content: center;
}

.footer-links-section{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.footer-explore-links, .footer-utility-links{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    row-gap: 10px;
}

.footer-desc{
    justify-self: center;
    font-weight: 300px;
    font-size: larger;
    width: 25%;
    margin: 2%;
}

.copyright{
    height: 100%;
    display: flex;
    justify-content: center;
}

.footer-book-appointment{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.text-footer{
    width: 80%;
    justify-content: space-evenly;
    height: 100%;

}

@media (max-width: 450px)  {
    .footer-section{
        width: 96%;
        padding: 2%;
    }
    .footer-content-data{
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
    }

    .footer-data{
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .footer-info-section{
        justify-content: space-evenly;
        align-items: center;
        width: 95%;
        margin: 2% 1%;
    }
    .footer-info{
        flex-direction: column;
    }

    .footer-links-section{
        flex-wrap: wrap;
        justify-content: space-evenly;
        height: 100%;
    }
    .footer-explore-links{
        height: 100%;
        justify-content: space-between;
    }
    .footer-utility-links{
        height: 100%;
        justify-content: space-between;
    }
    .footer-book-appointment{
        display: none;
    }

    .footer-desc{
        display: none;
    }

    .copyright{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}