.our-team-helps {
  width: 624.3px;
  position: relative;
  line-height: 114.5%;
  display: inline-block;
  height: 85.4px;
  flex-shrink: 0;
}


@media (max-width: 728px) {
  .our-team-of7 {
    display: flex;
    width: 100%;
    font-size: 10px;
    line-height: 142%;
    color: var(--color-dimgray-100);
    /* height: 70.3px; */
    align-content: center;
    justify-content: center;
    text-align: center;
  }
}
.our-team-of7 {
  margin: 5% 5%;
  display: flex;
  width: 100%;
  font-size: 16px;
  line-height: 142%;
  color: var(--color-dimgray-100);
  /* height: 70.3px; */
  align-content: center;
  justify-content: center;
  text-align: center;
}
.banner-text1 {
  position: absolute;
  top: 567.1px;
  left: calc(50% - 370px);
  width: 740.9px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xl);
  text-align: center;
  font-size: var(--font-size-21xl);
}
.the-leaders {
  position: relative;
  letter-spacing: 0.42em;
  line-height: 142%;
  text-transform: uppercase;
}
.sub-text8 {
  position: absolute;
  top: 1220.1px;
  left: 98px;
  border: 0.5px solid var(--color-lightgray-100);
  box-sizing: border-box;
  width: 163px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-10xs) var(--padding-6xs);
  font-size: var(--font-size-sm);
}
.we-have-the {
  width: 380.3px;
  position: relative;
  line-height: 130%;
  font-weight: 600;
  display: inline-block;
  flex-shrink: 0;
}
.title16 {
  position: absolute;
  width: 26.4%;
  top: 1259.1px;
  right: 66.81%;
  left: 6.8%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-11xl);
}
.collaboratively-administrate-e-container1 {
  position: absolute;
  width: 30.74%;
  top: 1352.1px;
  left: 6.8%;
  line-height: 142%;
  color: var(--color-dimgray-100);
  display: inline-block;
  height: 154px;
}
.sub-text-item {
  position: absolute;
  top: 0;
  left: 0;
  border: 0.5px solid var(--color-lightgray-100);
  box-sizing: border-box;
  width: 177.2px;
  height: 26.9px;
}
.our-story {
  position: absolute;
  top: 3.4px;
  left: 26.1px;
  letter-spacing: 0.42em;
  line-height: 142%;
  text-transform: uppercase;
}
.sub-text9 {
  width: 177.2px;
  position: relative;
  height: 26.9px;
}
.our-mission-is,
.title17 {
  width: 486.8px;
  height: 66.8px;
}
.our-mission-is {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 130%;
  font-weight: 600;
  display: inline-block;
}
.title17 {
  position: relative;
  font-size: var(--font-size-11xl);
}
.heading5 {
  position: absolute;
  width: 33.78%;
  top: 1220.1px;
  right: 5.28%;
  left: 60.93%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-smi);
  font-size: var(--font-size-sm);
}
.collaboratively-administrate-e-container2 {
  position: absolute;
  width: 32.06%;
  top: 1344.8px;
  left: 60.93%;
  line-height: 142%;
  color: var(--color-dimgray-100);
  display: inline-block;
  height: 68px;
}
.over-400-leading-hospitals-and {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  padding-left: var(--padding-2xl);
}
.award-winning-practice-managem-container,
.over-400-leading-container,
.trusted-doctors-across-container {
  position: absolute;
  width: 19.29%;
  top: 1430.8px;
  left: 60.51%;
  line-height: 181%;
  color: var(--color-dimgray-100);
  display: inline-block;
}
.award-winning-practice-managem-container,
.trusted-doctors-across-container {
  width: 19.43%;
  top: 1475.8px;
}
.award-winning-practice-managem-container {
  width: 19.08%;
  top: 1521px;
  height: 19px;
}
.background-icon1,
.video-icon {
  position: absolute;
  top: 786px;
  left: 81px;
  width: 1249px;
  height: 387.3px;
  object-fit: cover;
}
.background-icon1 {
  width: 100%;
  margin: 0 !important;
  top: 0;
  right: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  height: 391.2px;
  flex-shrink: 0;
  z-index: 0;
}
.online-consultations-with {
  margin: 0;
}
.online-consultations-with-container {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 130%;
  font-weight: 600;
  display: inline-block;
  width: 563px;
  height: 117px;
}
.title18 {
  width: 563px;
  position: relative;
  height: 117px;
}
.heading6 {
  width: 538px;
  height: 141px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.book-an-appointment2 {
  flex: 1;
  position: relative;
  line-height: 160%;
  cursor: pointer;
}
.button13 {
  width: 215px;
  background-color: var(--color-aquamarine);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-mid) var(--padding-15xl);
  box-sizing: border-box;
}
.call-1-800-123-9999,
.or {
  position: relative;
  line-height: 160%;
}
.or {
  font-size: var(--font-size-sm);
  color: var(--light-background-color);
}
.call-1-800-123-9999 {
  flex: 1;
}
.button12,
.button14 {
  display: flex;
  flex-direction: row;
}
.button14 {
  width: 199px;
  background-color: var(--light-background-color);
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-mid) 32px;
  box-sizing: border-box;
}
.button12 {
  align-self: stretch;
  align-items: center;
  justify-content: center;
  gap: var(--gap-2xl);
  text-align: left;
  font-size: var(--navigation-size);
  color: var(--color-gray-300);
}
.cta1,
.text17 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.text17 {
  width: 599px;
  height: 264px;
  align-items: center;
  gap: 32px;
  z-index: 1;
}
.cta1 {
  position: absolute;
  width: 100.21%;
  top: 1601px;
  right: 0.07%;
  left: -0.28%;
  height: 391px;
  align-items: flex-start;
  padding: var(--padding-56xl) 143px 50px 410px;
  box-sizing: border-box;
  gap: var(--gap-3xs);
  text-align: center;
  font-size: var(--font-size-31xl);
  color: var(--light-background-color);
}
.our-team-of8,
.our-values-that {
  width: 447.8px;
  position: relative;
  line-height: 114.5%;
  display: inline-block;
  height: 93.9px;
  flex-shrink: 0;
}
.our-team-of8 {
  width: 536.4px;
  font-size: var(--navigation-size);
  line-height: 142%;
  color: var(--color-dimgray-100);
  height: 95.1px;
}
.text18,
.value {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.text18 {
  width: 1200.2px;
  flex-direction: row;
  gap: 216px;
}
.value {
  position: absolute;
  top: calc(50% + 469px);
  left: 123px;
  width: 1213px;
  height: 95px;
  flex-direction: column;
  font-size: var(--font-size-21xl);
}
.photo-icon {
  object-fit: cover;
}
.photo,
.photo-icon {
  position: absolute;
  top: 133px;
  left: 0;
  width: 1440px;
  height: 352px;
}
.about-us2,
.home-about {
  position: absolute;
  top: 263px;
  left: calc(50% - 74px);
  font-size: var(--font-size-xl);
  line-height: 114.5%;
  font-weight: 600;
  color: var(--light-background-color);
  text-align: center;
}
.about-us2 {
  top: 306px;
  left: calc(50% - 62px);
  font-size: var(--font-size-13xl);
}
.about-child {
  position: absolute;
  top: 81px;
  left: 1152px;
  border-radius: 50%;
  background: linear-gradient(90deg, #0152a8, #0a67c8);
  width: 37px;
  height: 37px;
}
.search-icon1 {
  position: absolute;
  top: 88px;
  left: 1159px;
  width: 24px;
  height: 24px;
  overflow: hidden;
}
.icon18 {
  width: 125px;
  position: relative;
  height: 125px;
}
.bio-technology {
  position: relative;
  line-height: 114.5%;
  font-weight: 600;
}
.our-team-of9 {
  width: 204.7px;
  position: relative;
  font-size: var(--navigation-size);
  line-height: 142%;
  color: var(--color-dimgray-100);
  text-align: center;
  display: inline-block;
  height: 69.9px;
  flex-shrink: 0;
}
.text19 {
  width: 204.7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-sm);
}
.div20 {
  width: 264.7px;
  box-sizing: border-box;
}
.div20,
.div21 {
  border: 1px solid var(--color-gainsboro-200);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-21xl) var(--padding-11xl);
  gap: var(--gap-base);
}
.our-team-of11 {
  width: 204.7px;
  position: relative;
  font-size: var(--navigation-size);
  line-height: 142%;
  color: var(--color-dimgray-100);
  display: inline-block;
  height: 69.9px;
  flex-shrink: 0;
}
.div22,
.parent1 {
  display: flex;
  justify-content: flex-start;
}
.div22 {
  width: 264.7px;
  border: 1px solid var(--color-gainsboro-200);
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  padding: var(--padding-21xl) var(--padding-11xl);
  gap: var(--gap-base);
  text-align: center;
}
.parent1 {
  position: absolute;
  width: 86.45%;
  top: 2265px;
  right: 5.62%;
  left: 7.92%;
  height: 364px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 24px 23px;
  font-size: var(--font-size-xl);
}
.background-icon2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 1442px;
  height: 566.8px;
}
.copyright-pharma1 {
  position: absolute;
  top: 470.3px;
  left: calc(50% - 132px);
  line-height: 200%;
  display: inline-block;
  width: 280px;
  height: 59px;
}
.icon-inner,
.rectangle-div {
  position: absolute;
  top: 45.1px;
  left: 13.5px;
  border-radius: var(--br-10xl);
  background-color: var(--color-darkslateblue-100);
  width: 52.1px;
  height: 25.2px;
  transform: rotate(-60deg);
  transform-origin: 0 0;
}
.rectangle-div {
  top: 57.8px;
  left: 26.1px;
  background-color: var(--color-aquamarine);
  transform: rotate(-120deg);
}
.intersect-icon1 {
  position: absolute;
  top: 15.4px;
  left: 18.1px;
  width: 25.3px;
  height: 37.8px;
}
.icon22 {
  width: 61.4px;
  position: relative;
  height: 57.8px;
}
.pharma1 {
  position: relative;
  line-height: 105%;
  font-weight: 600;
}
.logo1 {
  width: 175.4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-10xs);
}
.div25 {
  position: absolute;
  top: 3.9px;
  left: 51.8px;
  line-height: 200%;
}
.call-item {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: var(--color-aquamarine);
  width: 43.1px;
  height: 43.1px;
}
.vector-icon4 {
  position: absolute;
  height: 35.5%;
  width: 7.16%;
  top: 31.79%;
  right: 86.48%;
  bottom: 32.71%;
  left: 6.36%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.call1 {
  width: 213.8px;
  position: relative;
  height: 43.1px;
}
.vector-icon5 {
  position: absolute;
  height: 31.09%;
  width: 7.94%;
  top: 34.57%;
  right: 86.16%;
  bottom: 34.34%;
  left: 5.91%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.mail1 {
  width: 221.8px;
  position: relative;
  height: 43.1px;
}
.fb-icon4,
.insta-icon2,
.twiter-icon4 {
  position: absolute;
  height: 100%;
  width: 19.1%;
  top: 0;
  right: 40.28%;
  bottom: 0;
  left: 40.61%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.insta-icon2,
.twiter-icon4 {
  width: 18.52%;
  right: 81.48%;
  left: 0;
}
.twiter-icon4 {
  height: 80.37%;
  width: 17.86%;
  top: 9.35%;
  right: 0;
  bottom: 10.28%;
  left: 82.14%;
}
.follow4 {
  width: 120.4px;
  position: relative;
  height: 21.4px;
}
.contact-details1,
.div24 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.contact-details1 {
  width: 662px;
  gap: var(--gap-34xl);
  font-size: var(--navigation-size);
  color: var(--color-dimgray-100);
  font-family: var(--font-lato);
}
.div24 {
  width: 1244px;
  gap: var(--gap-343xl);
}
.a-simple-story-container1 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 141%;
  font-weight: 600;
  display: inline-block;
  width: 369.6px;
  height: 158.3px;
}
.quotes3 {
  width: 369.6px;
  position: relative;
  height: 158.3px;
}
.quotes2 {
  width: 369.6px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-11xl);
}
.explore3 {
  position: relative;
  font-size: var(--font-size-xl);
  line-height: 114.5%;
  font-weight: 600;
  color: var(--color-gray-300);
}
.home2,
.news1,
.testimonals1 {
  position: relative;
  line-height: 200%;
  display: inline-block;
  height: 26px;
  flex-shrink: 0;
}
.home2 {
  width: 69px;
  cursor: pointer;
}
.news1,
.testimonals1 {
  width: 91px;
}
.news1 {
  width: 69px;
}
.explore2 {
  width: 96px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-sm);
  color: var(--color-dimgray-100);
}
.style-guide-changelog-container1 {
  position: relative;
  font-size: var(--navigation-size);
  line-height: 200%;
  color: var(--color-dimgray-100);
}
.uitility1 {
  width: 136px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
  cursor: pointer;
  font-size: var(--font-size-xl);
}
.it-is-a1 {
  width: 324.1px;
  position: relative;
  font-size: var(--navigation-size);
  line-height: 142%;
  color: var(--color-dimgray-100);
  display: inline-block;
  height: 50.7px;
  flex-shrink: 0;
}
.text23 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  font-size: var(--font-size-xl);
}
.bg1 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--light-background-color);
  width: 325.7px;
  height: 60px;
}
.your-email-address1 {
  position: absolute;
  top: 17px;
  left: 25.2px;
  line-height: 160%;
}
.button15 {
  width: 325.7px;
  position: relative;
  height: 60px;
  color: var(--color-silver-200);
}
.submit1 {
  position: relative;
  line-height: 160%;
  font-weight: 500;
}
.appoinment1,
.button16,
.content12,
.div26 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.button16 {
  background-color: var(--color-darkslateblue-100);
  flex-direction: row;
  padding: var(--padding-mid) var(--padding-23xl);
  color: var(--light-background-color);
  font-family: var(--navigation);
}
.appoinment1,
.content12,
.div26 {
  width: 325.7px;
  flex-direction: column;
  gap: var(--gap-base);
}
.content12,
.div26 {
  width: 1198.3px;
  flex-direction: row;
  gap: var(--gap-89xl);
  font-size: var(--navigation-size);
  font-family: var(--font-source-sans-pro);
}
.content12 {
  position: absolute;
  top: 48.9px;
  left: 120.6px;
  width: 1239.7px;
  height: 352.2px;
  flex-direction: column;
  gap: var(--gap-44xl);
  font-size: var(--font-size-11xl);
  color: var(--color-gray-300);
  font-family: var(--navigation);
}
.content11 {
  position: absolute;
  top: 2668px;
  left: -7px;
  width: 1442px;
  height: 566.8px;
  color: var(--color-dimgray-100);
}
.frame-child1 {
  position: absolute;
  top: 61px;
  left: 0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  background-color: var(--light-background-color);
  width: 1440px;
  height: 72px;
}
.background,
.frame-child2 {
  position: absolute;
  top: 93px;
  left: 751px;
  width: 14px;
  height: 14px;
}
.background {
  top: 0;
  left: 0;
  background-color: var(--color-darkslateblue-100);
  width: 1440px;
  height: 61px;
}
.welcome-to-pharma1 {
  position: absolute;
  top: 17.3px;
  left: calc(50% - 622.9px);
  line-height: 160%;
  display: inline-block;
  width: 353.5px;
}
.div27,
.icon23 {
  position: absolute;
}
.div27 {
  top: 18px;
  left: 1001.4px;
  line-height: 160%;
  display: inline-block;
  width: 132.2px;
  height: 18px;
}
.icon23 {
  top: 13px;
  left: 958.3px;
  width: 34.7px;
  height: 34.6px;
}
.abcgmailcom1 {
  position: absolute;
  top: 18px;
  left: 1222.7px;
  line-height: 160%;
  display: inline-block;
  width: 105.1px;
}
.icon24,
.top-bar1 {
  position: absolute;
  top: 13px;
  left: 1179.6px;
  width: 34.7px;
  height: 34.6px;
}
.top-bar1 {
  top: 0;
  left: 0;
  width: 1440px;
  height: 61px;
}
.home3 {
  position: relative;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.about2,
.home3 {
  letter-spacing: 0.01em;
  line-height: 100%;
  cursor: pointer;
}
.about2,
.icon25,
.pages1 {
  position: relative;
}
.pages1 {
  letter-spacing: 0.01em;
  line-height: 100%;
}
.icon25 {
  width: 18px;
  height: 18px;
  overflow: hidden;
  flex-shrink: 0;
  opacity: 0.5;
}
.button17,
.dropdown1 {
  border-radius: var(--br-5xs);
}
.button17 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--light-background-color);
  border: 1px solid var(--black);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-base);
}
.dropdown1 {
  width: 45px;
  position: relative;
  height: 24px;
}
.menu-container,
.menu1 {
  position: absolute;
  width: 510px;
  height: 47px;
}
.menu1 {
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.menu-container {
  top: 87px;
  left: 529px;
  color: var(--primary);
  font-family: var(--navigation);
}
.book-appointment2 {
  position: relative;
  line-height: 160%;
  cursor: pointer;
}
.book-appointment-container {
  position: absolute;
  top: 79px;
  left: 1178px;
  border-radius: var(--br-4xs);
  background: linear-gradient(90deg, #0152a8, #0e6acc);
  width: 166px;
  height: 41px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  text-align: center;
}
.rectangle-container {
  position: absolute;
  top: 0;
  left: 5px;
  width: 1440px;
  height: 133px;
  color: var(--light-background-color);
  font-family: var(--font-calibri);
}
.group-icon {
  width: 73px;
  position: relative;
  height: 50.3px;
}
.about-inner {
  position: absolute;
  top: 61px;
  left: 96px;
  height: 72px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.about-item {
  position: absolute;
  top: 85px;
  left: 1105px;
  border-radius: 50%;
  background: linear-gradient(90deg, #0152a8, #0a67c8);
  width: 37px;
  height: 37px;
}
.schedule-title{
  font-weight: 600;
  font-family: "Source Sans Pro";
  font-size: 30px;
  width: 50%;
}
.search-icon2 {
  position: absolute;
  top: 91px;
  left: 1111px;
  width: 24px;
  height: 24px;
  overflow: hidden;
  cursor: pointer;
}
.schedule-section{
  display: flex;
  justify-content: center;
  align-items: center;
}
.about1 {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: var(--light-background-color);
  display:flex;
  flex-direction: column;
  height: auto;
  text-align: left;
  font-size: var(--navigation-size);
  color: var(--color-gray-300);
  font-family: var(--font-source-sans-pro);
}

@media (max-width: 468px){
  .schedule-title{
    font-size: 20px;
    width: 100%;
  }

  .subscribe-btn{
    font-size: 10px;
  }
}