.about-us10,
.home-about5 {
  line-height: 114.5%;
  display: inline-block;
}
.about-us10 {
  position: absolute;
  top: 163px;
  left: calc(50% - 99px);
  width: 198px;
  height: 47px;
}
.home-about5 {
  top: 126px;
  left: calc(50% - 89px);
  font-size: var(--font-size-5xl);
  font-weight: 600;
  width: 177px;
  height: 24px;
}
.home-about5,
.image9,
.photo8 {
  position: absolute;
}
.photo8 {
  top: -49px;
  left: -11.5px;
  background-color: var(--color-gray-400);
  width: 1461.9px;
  height: 643.4px;
}
.image9 {
  top: 133px;
  left: calc(50% - 720px);
  width: 1440px;
  height: 352px;
  display: none;
  font-size: var(--font-size-31xl);
}
.home-pages1 {
  position: absolute;
  top: 263px;
  left: calc(50% - 87px);
  font-size: var(--font-size-xl);
  line-height: 114.5%;
  font-weight: 600;
}
.uniquely-matrix-economically {
  align-self: stretch;
  position: relative;
  line-height: 142%;
}
.the-perfect-health1 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 130%;
  font-weight: 600;
  display: inline-block;
  width: 384px;
}
.heading12,
.title37 {
  width: 384px;
  height: 39px;
}
.title37 {
  position: absolute;
  top: 0;
  left: 0;
}
.heading12 {
  position: relative;
}
.appropriately-empower-dynamic1 {
  width: 590px;
  position: relative;
  line-height: 142%;
  display: inline-block;
}
.we-will-strengthen-local-healt {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  padding-left: var(--padding-2xl);
}
.health-care-is-container,
.providing-optimal-multidiscipl-container,
.we-will-strengthen-container {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 142%;
  display: inline-block;
  width: 477px;
}
.health-care-is-container,
.providing-optimal-multidiscipl-container {
  top: 33px;
}
.health-care-is-container {
  top: 66px;
}
.points1 {
  width: 477px;
  position: relative;
  height: 89px;
  font-size: var(--navigation-size);
  color: var(--color-darkslateblue-100);
}
.content40,
.text54,
.text55 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.text55 {
  align-items: center;
  gap: var(--gap-mini);
  font-size: var(--font-size-mini);
  color: var(--color-dimgray-100);
  font-family: var(--font-lato);
}
.content40,
.text54 {
  gap: var(--gap-xl);
}
.content40 {
  align-items: flex-start;
  font-size: var(--font-size-11xl);
  color: var(--color-gray-300);
  font-family: var(--font-montserrat);
}
.text54 {
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
}
.text53 {
  width: 590px;
  position: relative;
  height: 372px;
  font-size: var(--font-size-mini);
}
.image-icon26 {
  width: 583px;
  position: relative;
  height: 406.9px;
  object-fit: cover;
}
.content41 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.doctor-with-patient1 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 142%;
}
.text56 {
  width: 143px;
  position: relative;
  height: 23px;
}
.image10 {
  width: 583px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.enthusiastically-mesh-long-ter {
  width: 590px;
  position: relative;
  font-size: var(--navigation-size);
  line-height: 142%;
  font-family: var(--font-lato);
  color: var(--color-dimgray-100);
  display: inline-block;
}
.text57 {
  width: 590px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
  font-size: var(--font-size-11xl);
  color: var(--color-gray-300);
  font-family: var(--font-montserrat);
}
.quotes-item {
  width: 8.3px;
  position: relative;
  background-color: var(--color-darkslateblue-100);
  height: 130.5px;
}
.professionally-fashion-wireles {
  margin: 0;
}
.professionally-fashion-wireles-container {
  position: relative;
  line-height: 142%;
  font-weight: 500;
}
.quotes12,
.quotes13 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.quotes13 {
  background-color: var(--color-lavender);
  padding: var(--padding-7xl) var(--padding-56xl);
}
.quotes12 {
  width: 598.3px;
  font-size: var(--font-size-lg);
  color: var(--color-darkslateblue-100);
}
.feel-like-home3 {
  position: relative;
  line-height: 142%;
  font-weight: 600;
}
.collaboratively-administrate-e5 {
  width: 590px;
  position: relative;
  font-size: var(--navigation-size);
  line-height: 142%;
  color: var(--color-dimgray-100);
  text-align: left;
  display: inline-block;
}
.text58 {
  width: 590px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
  text-align: center;
  font-size: var(--font-size-11xl);
  color: var(--black);
}
.leverage-agile-frameworks-container2 {
  width: 348px;
  position: relative;
  line-height: 142%;
  display: inline-block;
}
.provide-a-robust-container1 {
  position: relative;
  line-height: 142%;
}
.content39,
.text59 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.text59 {
  width: 531px;
  align-items: flex-start;
  gap: var(--gap-mini);
  opacity: 0.9;
}
.content39 {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  align-items: center;
  gap: var(--gap-smi);
}
.line6 {
  width: 531px;
  position: relative;
  border-top: 1px solid var(--color-silver-100);
  box-sizing: border-box;
  height: 1px;
}
.loreim-ipsum-dolor {
  width: 530px;
  position: relative;
  line-height: 142%;
  display: inline-block;
}
.user {
  width: 531.1px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xl);
}
.comment,
.pattern-child {
  position: absolute;
  box-sizing: border-box;
}
.comment {
  width: 98.46%;
  top: 1482.3px;
  right: 0.77%;
  left: 0.77%;
  background-color: var(--color-whitesmoke-200);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-9xl) 29px;
}
.pattern-child {
  width: 100.17%;
  top: 63px;
  right: -0.17%;
  left: 0;
  border-top: 1px solid var(--color-whitesmoke-100);
  height: 1px;
}
.pattern,
.pattern-item {
  position: absolute;
  top: 0;
  left: 0;
}
.pattern-item {
  width: 100.17%;
  right: -0.17%;
  border-top: 1px solid var(--color-whitesmoke-100);
  box-sizing: border-box;
  height: 1px;
}
.pattern {
  width: 100%;
  right: 0;
  height: 63px;
}
.share-the-article {
  position: relative;
  line-height: 130%;
  font-weight: 600;
}
.div59,
.div62 {
  position: relative;
  letter-spacing: 0.06em;
  line-height: 120%;
  text-transform: uppercase;
}
.div62 {
  font-size: 19px;
}
.icons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 54px;
  text-align: center;
  color: var(--color-gray-200);
  font-family: var(--font-font-awesome-5-brands);
}
.share,
.text60 {
  position: absolute;
}
.text60 {
  width: 89.83%;
  top: 20px;
  right: 5.08%;
  left: 5.08%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 142px;
}
.share {
  width: 98.61%;
  top: 1758.3px;
  right: 0.69%;
  left: 0.7%;
  height: 63px;
  font-size: var(--font-size-lg);
  color: var(--color-gray-300);
  font-family: var(--font-montserrat);
}
.leave-a-comment {
  position: relative;
  line-height: 114.5%;
  font-weight: 600;
}
.title38 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.bg5,
.btn1 {
  width: 590px;
  height: 60px;
}
.bg5 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--light-background-color);
  border: 1px solid var(--color-gainsboro-400);
  box-sizing: border-box;
}
.btn1 {
  position: relative;
}
.email {
  width: 590px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.website {
  top: 17.5px;
  left: 31px;
  line-height: 160%;
}
.bg8,
.message1,
.website {
  position: absolute;
}
.bg8 {
  top: 0;
  left: 0;
  background-color: var(--light-background-color);
  border: 1px solid var(--color-gainsboro-400);
  box-sizing: border-box;
  width: 590px;
  height: 180px;
}
.message1 {
  top: 20px;
  left: 31px;
  line-height: 160%;
}
.btn4 {
  width: 590px;
  position: relative;
  height: 180px;
}
.post-a-comment {
  position: relative;
  line-height: 160%;
  font-weight: 500;
}
.button40,
.form,
.form1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.button40 {
  background-color: var(--color-darkslateblue-100);
  flex-direction: row;
  padding: var(--padding-mid) 33px;
  color: var(--light-background-color);
}
.form,
.form1 {
  flex-direction: column;
  gap: var(--gap-xl);
  font-size: var(--navigation-size);
  color: var(--color-silver-200);
  font-family: var(--navigation);
}
.form {
  position: absolute;
  width: 98.61%;
  top: 1876.3px;
  right: 0.69%;
  left: 0.7%;
  font-size: var(--font-size-11xl);
  color: var(--color-gray-300);
  font-family: var(--font-montserrat);
}
.content38 {
  position: absolute;
  width: 41.55%;
  top: 1227px;
  right: 29.22%;
  left: 29.24%;
  height: 2430.3px;
  text-align: left;
  color: var(--color-dimgray-100);
  font-family: var(--font-lato);
}
.banner3 {
  position: absolute;
  top: 218px;
  left: calc(50% - 722px);
  width: 1440px;
  height: 922px;
}
.background6 {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  background-color: var(--color-lavender);
  height: 434px;
}
.july-20232 {
  position: relative;
  letter-spacing: 0.42em;
  line-height: 142%;
  text-transform: uppercase;
}
.sub-text19 {
  width: 187px;
  border: 0.5px solid var(--color-lightgray-100);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-10xs) var(--padding-9xl) var(--padding-10xs)
    var(--padding-8xl);
}
.potential-treatment-for {
  position: relative;
  line-height: 130%;
}
.title39 {
  width: 1151px;
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  font-size: var(--font-size-21xl);
  font-family: var(--font-montserrat);
}
.heading13,
.image-background {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.heading13 {
  width: 1200px;
  gap: var(--gap-31xl);
}
.image-background {
  align-self: stretch;
}
.image-icon27 {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 550px;
  flex-shrink: 0;
  object-fit: cover;
}
.line7 {
  position: absolute;
  top: 124.5px;
  left: -0.5px;
  border-top: 1px solid var(--color-silver-100);
  box-sizing: border-box;
  width: 1201px;
  height: 1px;
}
.user1 {
  width: 1200.7px;
  position: relative;
  height: 125px;
}
.banner4,
.content42 {
  position: absolute;
  height: 907px;
}
.content42 {
  width: 90.51%;
  top: 0;
  right: 6.65%;
  left: 2.85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-31xl);
}
.banner4 {
  width: 100%;
  top: 245px;
  right: 0.14%;
  left: -0.14%;
  font-size: var(--font-size-sm);
  color: var(--color-gray-300);
  font-family: var(--font-lato);
}
.background-icon6 {
  position: absolute;
  top: 0;
  left: 0;
  width: 1440px;
  height: 556.8px;
}
.copyright-pharma5 {
  position: absolute;
  top: 462px;
  left: calc(50% - 81.9px);
  line-height: 200%;
  display: inline-block;
  width: 279.6px;
  height: 58px;
}
.icon-child7,
.icon-child8 {
  position: absolute;
  top: 45.1px;
  left: 13.5px;
  border-radius: var(--br-10xl);
  background-color: var(--color-darkslateblue-100);
  width: 52.1px;
  height: 25.2px;
  transform: rotate(-60deg);
  transform-origin: 0 0;
}
.icon-child8 {
  top: 57.8px;
  left: 26.1px;
  background-color: var(--color-aquamarine);
  transform: rotate(-120deg);
}
.intersect-icon5 {
  position: absolute;
  top: 15.4px;
  left: 18.1px;
  width: 25.3px;
  height: 37.8px;
}
.icon48 {
  width: 61.4px;
  position: relative;
  height: 57.8px;
}
.pharma5 {
  position: relative;
  line-height: 105%;
  font-weight: 600;
}
.logo5 {
  width: 175.4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-10xs);
}
.div64 {
  position: absolute;
  top: 3.9px;
  left: 51.8px;
  line-height: 200%;
}
.call-child3 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: var(--color-aquamarine);
  width: 43.1px;
  height: 43.1px;
}
.vector-icon12 {
  position: absolute;
  height: 35.5%;
  width: 7.16%;
  top: 31.79%;
  right: 86.48%;
  bottom: 32.71%;
  left: 6.36%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.call5 {
  width: 213.8px;
  position: relative;
  height: 43.1px;
}
.vector-icon13 {
  position: absolute;
  height: 31.09%;
  width: 7.94%;
  top: 34.57%;
  right: 86.16%;
  bottom: 34.34%;
  left: 5.91%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.mail5 {
  width: 221.8px;
  position: relative;
  height: 43.1px;
}
.fb-icon11,
.insta-icon7,
.twiter-icon11 {
  position: absolute;
  height: 100%;
  width: 19.1%;
  top: 0;
  right: 40.28%;
  bottom: 0;
  left: 40.61%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.insta-icon7,
.twiter-icon11 {
  width: 18.52%;
  right: 81.48%;
  left: 0;
}
.twiter-icon11 {
  height: 80.37%;
  width: 17.86%;
  top: 9.35%;
  right: 0;
  bottom: 10.28%;
  left: 82.14%;
}
.follow11 {
  width: 120.4px;
  position: relative;
  height: 21.4px;
}
.contact-details5,
.div63 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.contact-details5 {
  width: 662px;
  gap: var(--gap-34xl);
  font-size: var(--navigation-size);
  color: var(--color-dimgray-100);
  font-family: var(--font-lato);
}
.div63 {
  width: 1244px;
  gap: var(--gap-343xl);
}
.a-simple-story-container5 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 141%;
  font-weight: 600;
  display: inline-block;
  width: 369.6px;
  height: 158.3px;
}
.quotes15 {
  width: 369.6px;
  position: relative;
  height: 158.3px;
}
.quotes14 {
  width: 369.6px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-11xl);
}
.explore11 {
  position: relative;
  font-size: var(--font-size-xl);
  line-height: 114.5%;
  font-weight: 600;
  color: var(--color-gray-300);
}
.home10,
.news5,
.testimonals5 {
  position: relative;
  line-height: 200%;
  display: inline-block;
  height: 26px;
  flex-shrink: 0;
}
.home10 {
  width: 69px;
  cursor: pointer;
}
.news5,
.testimonals5 {
  width: 91px;
}
.news5 {
  width: 69px;
}
.explore10 {
  width: 96px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-sm);
  color: var(--color-dimgray-100);
}
.style-guide-changelog-container5 {
  position: relative;
  font-size: var(--navigation-size);
  line-height: 200%;
  color: var(--color-dimgray-100);
}
.uitility5 {
  width: 136px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
  cursor: pointer;
  font-size: var(--font-size-xl);
}
.it-is-a5 {
  width: 324.1px;
  position: relative;
  font-size: var(--navigation-size);
  line-height: 142%;
  color: var(--color-dimgray-100);
  display: inline-block;
  height: 50.7px;
  flex-shrink: 0;
}
.text61 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  font-size: var(--font-size-xl);
}
.bg9 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--light-background-color);
  width: 325.7px;
  height: 60px;
}
.your-email-address5 {
  position: absolute;
  top: 17px;
  left: 25.2px;
  line-height: 160%;
}
.button41 {
  width: 325.7px;
  position: relative;
  height: 60px;
  color: var(--color-silver-200);
}
.appoinment5,
.button42,
.content44,
.div65 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.button42 {
  background-color: var(--color-darkslateblue-100);
  flex-direction: row;
  padding: var(--padding-mid) var(--padding-23xl);
  color: var(--light-background-color);
  font-family: var(--navigation);
}
.appoinment5,
.content44,
.div65 {
  width: 325.7px;
  flex-direction: column;
  gap: var(--gap-base);
}
.content44,
.div65 {
  width: 1198.3px;
  flex-direction: row;
  gap: var(--gap-89xl);
  font-size: var(--navigation-size);
  font-family: var(--font-source-sans-pro);
}
.content44 {
  position: absolute;
  top: 48px;
  left: 120.4px;
  width: 1264.9px;
  height: 397px;
  flex-direction: column;
  gap: var(--gap-44xl);
  font-size: var(--font-size-11xl);
  color: var(--color-gray-300);
  font-family: var(--navigation);
}
.content43,
.frame-child14 {
  position: absolute;
  left: 0;
  width: 1440px;
}
.content43 {
  top: 3707px;
  height: 556.8px;
  text-align: left;
  color: var(--color-dimgray-100);
}
.frame-child14 {
  top: 61px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  background-color: var(--light-background-color);
  height: 72px;
}
.home11 {
  position: relative;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.about6,
.home11 {
  letter-spacing: 0.01em;
  line-height: 100%;
  cursor: pointer;
}
.about6,
.icon49,
.pages5 {
  position: relative;
}
.pages5 {
  letter-spacing: 0.01em;
  line-height: 100%;
}
.icon49 {
  width: 18px;
  height: 18px;
  overflow: hidden;
  flex-shrink: 0;
  opacity: 0.5;
}
.button43,
.dropdown5 {
  border-radius: var(--br-5xs);
}
.button43 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--light-background-color);
  border: 1px solid var(--black);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-base);
}
.dropdown5 {
  width: 45px;
  position: relative;
  height: 24px;
}
.menu5 {
  position: absolute;
  top: 93px;
  left: 451px;
  width: 510px;
  height: 47px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  color: var(--primary);
  font-family: var(--navigation);
}
.background7 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-darkslateblue-100);
  width: 1440px;
  height: 61px;
}
.welcome-to-pharma5 {
  position: absolute;
  top: 17.3px;
  left: calc(50% - 622.9px);
  line-height: 160%;
  display: inline-block;
  width: 353.5px;
}
.div66,
.icon50 {
  position: absolute;
}
.div66 {
  top: 18px;
  left: 1001.4px;
  line-height: 160%;
  display: inline-block;
  width: 132.2px;
  height: 18px;
}
.icon50 {
  top: 13px;
  left: 958.3px;
  width: 34.7px;
  height: 34.6px;
}
.abcgmailcom5 {
  position: absolute;
  top: 18px;
  left: 1222.7px;
  line-height: 160%;
  display: inline-block;
  width: 105.1px;
}
.icon51,
.top-bar5 {
  position: absolute;
  top: 13px;
  left: 1179.6px;
  width: 34.7px;
  height: 34.6px;
}
.top-bar5 {
  top: 0;
  left: 0;
  width: 1440px;
  height: 61px;
}
.book-appointment6 {
  position: relative;
  line-height: 160%;
  cursor: pointer;
}
.book-appointment-wrapper3 {
  position: absolute;
  top: 79px;
  left: 1178px;
  border-radius: var(--br-4xs);
  background: linear-gradient(90deg, #0152a8, #0e6acc);
  width: 166px;
  height: 41px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  text-align: center;
}
.rectangle-parent4 {
  position: absolute;
  top: 0;
  left: 0;
  width: 1440px;
  height: 133px;
  text-align: left;
  font-family: var(--font-calibri);
}
.frame-child15 {
  width: 73px;
  position: relative;
  height: 50.3px;
}
.blog-single-inner {
  position: absolute;
  top: 61px;
  left: 96px;
  height: 72px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.blog-single-child {
  position: absolute;
  top: 81px;
  left: 1116px;
  border-radius: 50%;
  background: linear-gradient(90deg, #0152a8, #0a67c8);
  width: 37px;
  height: 37px;
}
.search-icon7 {
  position: absolute;
  top: 88px;
  left: 1123px;
  width: 24px;
  height: 24px;
  overflow: hidden;
}
.blog-single {
  width: 100%;
  position: relative;
  background-color: var(--light-background-color);
  height: 4327px;
  overflow: hidden;
  text-align: center;
  font-size: var(--navigation-size);
  color: var(--light-background-color);
  font-family: var(--font-source-sans-pro);
}
