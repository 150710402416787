.sidebar {
    position: fixed;
    top: 0;
    left: -300px;
    width: 250px;
    height: 100%;
    background: white;
    transition: left 0.3s ease;
    padding: 20px;
    z-index: 1000;
  }
  
  .sidebar.open {
    left: 0;
  }
  
  .sidebar nav a {
    display: block;
    padding: 10px;
    text-decoration: none;
  }
  
  .sidebar nav a:hover {
    background: skyblue;
  }
  
  .close-button {
    background: none;
    border: 1px solid;
    color: blue;
    font-size: 24px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  