.testimonial-card {
    border: 2px solid #e1e1e1;
    padding: 16px;
    background-color: rgba(241, 241, 241, 1);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .testimonial-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .testimonial-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 16px;
  }
  
  .testimonial-info h3 {
    margin: 0;
    font-size: 1.2em;
  }
  
  .testimonial-info p {
    margin: 0;
    color: #777;
    font: Montserrat;
  }
  
  .testimonial-quote {
    font-size: 2em;
    color: #007bff;
  }
  
  .testimonial-body {
    margin-top: 16px;
  }
  
  .testimonial-footer {
    color: #ffb400;
    display: flex;
    justify-content: space-around;
  }
  
  .testimont-profile{
    display: flex;
  }
  .star {
    font-size: 2em;
    color: #ffb400;
  }